import {createStore} from "redux";
import {ReactStateDeclaration} from "@uirouter/react";
import {SET_ACTIVE_STATE, SET_TITLE, TOGGLE_DRAWER, TOGGLE_SIDE_NAV} from "./constants";

// export interface AppStateType {
//     drawerOpen: boolean,
//     sideNavOpen: boolean,
//     activeState?: ReactStateDeclaration,
//     title: string,
//     loader: boolean
// }

const initialState = {
    drawerOpen: true,
    sideNavOpen: true,
    user: null,
    userData: [],
    title: 'Seller Dashboard',
    setting: null,
    loader: false,
    permissions: [],
    reports: {}
};
export const SET_USER = Symbol("SET_USER");
export const SET_SETTING = Symbol("SET_SETTING");
export const SET_LOADER = Symbol("SET_LOADING");
export const SET_PERMISSIONS = Symbol("SET_PERMISSIONS");
export const SET_ORDER_REPORT = Symbol("SET_ORDER_REPORT");
export const SET_PROFIT_REPORT = Symbol("SET_PROFIT_REPORT");
export const SET_PAYMENTS_REPORT = Symbol("SET_PAYMENTS_REPORT");
export const SET_SALES_REPORT = Symbol("SET_SALES_REPORT");
export const SET_ORDERS = Symbol("SET_ORDERS");
export const SET_PRODUCTS = Symbol("SET_PRODUCTS");


export const store = createStore((state: AppStateType = initialState, action: any) => {
    const {type} = action;
    switch (type) {
        case TOGGLE_DRAWER:
            return {...state, drawerOpen: action.open};
        case TOGGLE_SIDE_NAV:
            return {...state, sideNavOpen: action.sideNavOpen};
        case SET_USER:
            return {...state, user: action.user};
        case SET_SETTING:
            return {...state, setting: action.setting};
        case SET_ACTIVE_STATE:
            return {...state, activeState: action.activeState};
        case SET_TITLE:
            return {...state, title: action.title};
        case SET_LOADER:
            return {...state, loader: action.loader};
        case SET_PERMISSIONS:
            return {...state, permissions: action.permissions};
        case SET_ORDER_REPORT:
            state.reports = {
                'orderReports': action.orderReport
            }
            return {
                ...state, 
            };
        case SET_PROFIT_REPORT:
            state.reports = {
                'profitReports': action.profitReport
            }
            return {
                ...state, 
            };
        case SET_PAYMENTS_REPORT:
            state.reports = {
                'paymentReports': action.paymentReports
            }
            return {
                ...state, 
            };
        case SET_SALES_REPORT:
            state.reports = {
                'salesReport': action.salesReport
            }
            return {
                ...state, 
            };
        case SET_ORDERS:
            state.reports = {
                'orders': action.orders
            }
            return {
                ...state, 
            };
        case SET_PRODUCTS:
            state.reports = {
                'product': action.product
            }
            return {
                ...state, 
            };
        default:
            return state;
    }
});


