import {Button, Card, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, TextField, Theme, Typography, useTheme, withStyles} from "@material-ui/core";
import * as React from "react";
import {useState,useContext} from "react";
import {ReactStateDeclaration} from "@uirouter/react";
import {$crud} from "../Services/CrudFactory";
import {$state} from "../router";
import {$user} from "../Services/UserFactory";
import {deepOrange} from "@material-ui/core/colors";
import { Arabic } from "./Arabic/Arabic";
import LanguageContext from "../LanguageContext/LanguageContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogBox:{
            flexDirection:"column !important"
          },
          actionsContainer:{
            gridGap:"1rem",
            flexWrap:"nowrap",
            padding:"1rem 0",
            margin:"0 1rem"
          }
    })
)
const ColorButton = withStyles((theme: Theme) => ({
    root: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
        '&:hover': {
            backgroundColor: deepOrange[700],
        },
    },
}))(Button);


export function ChangePassword(props) {
    //language context
    const { isArb } = useContext(LanguageContext)
    const classes = useStyles({})

    const theme = useTheme();

    const [params, setParams] = useState({
        password: "",
        confirm_password: "",
    });

    const [loading, setLoading] = useState(false);

    const setParam = (name, value) => {
        setParams(prev => {
            return {
                ...prev,
                [name]: value
            }
        });
    };
    const clearValues = () => {
        setParams({
            password: '',
            confirm_password: '',
        })
    };
    const [errors, setErrors] = useState({any: []})
    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        //Name
        if (!params.password) {
            formIsValid = false;
            errors["password"] = isArb ? Arabic.this_field_is_required:'This field required';
            errors["passwordError"] = true;
        }
        //Password
        if (!params.confirm_password) {
            formIsValid = false;
            errors["confirm_password"] = isArb ? Arabic.this_field_is_required:'This field required';
            errors["confirm_passwordError"] = true;
        }
        setErrors(errors);
        return formIsValid;
    }

    const changePassword = async () => {
        setLoading(true);
        if (handleValidation()) {
            if (params.password !== params.confirm_password) {
                $crud.notify({
                    type: 'error',
                    message: isArb ? Arabic.passwords_does_not_match:'Passwords did not match'
                })
            } else {
                setLoading(true);
                try {
                    await $crud.post('user/change_password', params);
                    logout();
                } catch (e) {
                    console.log(e)
                }
            }
        }
        setLoading(false);
    };

    const logout = async () => {
        try {
            await $user.logout();
        } finally {
            $state.go("login");
        }
    };

    return (
        <Dialog
            maxWidth={"sm"}
            open={props.open}
            className={classes.dialogBox}
            onClose={() => props.handleClose()}
        >
            <DialogTitle>
                <Typography variant="h5">
                    <strong>{isArb ? Arabic.change_password:"Change Password"}</strong>
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container style={{gridGap:"1rem"}}>
                    <Grid item container>
                        <TextField 
                            autoComplete="off" 
                            error={errors.passwordError} 
                            helperText={errors.password} 
                            label={ isArb ? Arabic.new_password:'New Password' } 
                            value={params.password} 
                            onChange={e => setParam("password", e.target.value)} 
                            id="outlined-size-small" 
                            type="password" 
                            variant="outlined" 
                            size="small" 
                            fullWidth
                        />
                    </Grid>
                    <Grid item container>
                        <TextField 
                            autoComplete="off" 
                            error={errors.confirm_passwordError} 
                            helperText={errors.confirm_password} 
                            label={ isArb ? Arabic.confirm_password:'Confirm Password' } 
                            value={params.confirm_password} 
                            onChange={e => setParam("confirm_password", e.target.value)} 
                            id="outlined-size-small" 
                            type="password" 
                            variant="outlined" 
                            size="small" 
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
            <Grid container className={classes.actionsContainer}>
                <Grid item container sm={6}>
                    <Button 
                        onClick={props.handleClose} 
                        color="primary"
                        variant="outlined"
                        fullWidth
                    >
                        {isArb ? Arabic.cancel : "Cancel"}
                    </Button>
                </Grid>
                <Grid item container sm={6}>
                    <Button 
                        disabled={loading} 
                        variant="contained" 
                        color={"primary"}
                        fullWidth
                        onClick={changePassword}
                    >
                             {loading ? isArb ? Arabic.in_process:'In Process..' : isArb ? Arabic.submit:'Submit'}
                    </Button>
                </Grid>
            </Grid>
            </DialogActions>
        </Dialog>
    )
    // <Grid item xs container justify="center" alignContent="center">
    //     <Grid item xs={12} sm={5} md={5} className="p-3" container direction="column">
    //         <Card className="" component={"form"} onSubmit={e => {
    //             e.preventDefault();
    //             changePassword();
    //         }}>
    //             <Grid container alignContent="center" className="text-white" style={{height: "100px", backgroundColor: theme.palette.primary.main}}>
    //                 <Typography variant="h4" align="center" className="w-100">{isArb ? Arabic.change_password:"Change Password"}</Typography>
    //             </Grid>
    //             <Grid className="p-2">
    //                 <Grid item className="p-2 mt-2">
    //                     <TextField autoComplete="off" error={errors.passwordError} helperText={errors.password} label={ isArb ? Arabic.new_password:'New Password' } value={params.password} onChange={e => setParam("password", e.target.value)} id="outlined-size-small" type="password" className='mb-3' variant="standard" size="small" fullWidth={true}/>
    //                 </Grid>
    //                 <Grid item  className="p-2">
    //                     <TextField autoComplete="off" error={errors.confirm_passwordError} helperText={errors.confirm_password} label={ isArb ? Arabic.confirm_password:'Confirm Password' } value={params.confirm_password} onChange={e => setParam("confirm_password", e.target.value)} id="outlined-size-small" type="password" className='mb-3' variant="standard" size="small" fullWidth={true}/>
    //                 </Grid>
    //                 <Grid container justify="flex-end" className="mt-2 p-2">
    //                     <ColorButton type="button" onClick={clearValues} variant="contained" className="mr-2" color={"primary"}>{ isArb ? Arabic.clear:'Clear' }</ColorButton>
    //                     <Button disabled={loading} type="submit" variant="contained" color={"primary"}>
    //                         {loading ? isArb ? Arabic.in_process:'In Process..' : isArb ? Arabic.submit:'Submit'}
    //                     </Button>
    //                 </Grid>
    //             </Grid>
    //         </Card>
    //     </Grid>
    // </Grid>
}


export const states: ReactStateDeclaration[] = [
    {
        url: "/change-password",
        name: "changePassword",
        component: ChangePassword,
        data: {
            title: "Change Password",
            loggedIn: true
        }
    }
];
