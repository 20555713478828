import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import LanguageContext from '../../LanguageContext/LanguageContext';
import { Arabic } from '../Arabic/Arabic';

export default class EditOrder extends React.Component {
    //language context
    static contextType = LanguageContext

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const { isArb } = this.context

        return (
            <Dialog
                maxWidth={"sm"}
                fullWidth={true}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/*<DialogTitle id="alert-dialog-title">Please follow the steps</DialogTitle>*/}
                <DialogContent className="pl-3 pr-3 pt-2 pb-2">
                    {
                        <Grid container direction="row" justify={"center"}>
                            <Grid item xs={12} sm={12} md={12} className="p-2 text-center">
                                <img className="w-100" src={this.props.image} alt={ isArb ? Arabic.info_image:'info image' }/>
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions className="pt-2 pb-2 pr-3 pl-3">
                    <Button onClick={this.props.handleClose} variant={"contained"} color="secondary">
                        { isArb ? Arabic.close:'Close' }
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

