import { $user } from "./UserFactory";
import { CrudRequest } from "@crud/core";
import { chooseFile } from "@crud/web";
import { hide_loader, show_loader } from "../StoreDispatch";

// export interface ResponseType<T = any> {
//     data: T,
//     message: string,
//     status_code: 200 | 400 | 401 | number
// }

export class CrudFactory extends CrudRequest {
  // baseUrl = "AXIOS_BASE_URL";
  // baseUrl = "http://3.23.223.86:9002/";
  baseUrl = "https://sellerapi.dev.zofido.com/";
  // baseUrl = "http://localhost:9002/";
  // baseUrl = "https://foodselleradmin.zofido.com/";

  getUrl = (...segments) =>
    segments.reduce((url, segment) => url + segment, this.baseUrl);

  constructor() {
    super();
    this.config(chooseFile);
  }

  async get(url, data = {}, requestOptions = {}) {
    return this.send({
      method: "GET",
      url,
      data,
      ...requestOptions,
    });
  }

  async post(url, data = {}, requestOptions = {}) {
    return this.send({
      method: "POST",
      url,
      data,
      ...requestOptions,
    });
  }

  async put(url, data = {}, requestOptions = {}) {
    return this.send({
      method: "PUT",
      url,
      data,
      ...requestOptions,
    });
  }

  // async patch<Request = any, Response = any>(url: string, data: any = {}, requestOptions: RequestOptions = {}): Promise<ResponseType<Response>> {
  //     return this.send({
  //         method: "PATCH",
  //         url,
  //         data,
  //         ...requestOptions
  //     });
  // }

  async delete(url, data = {}, requestOptions = {}) {
    return this.send({
      method: "DELETE",
      url,
      data,
      ...requestOptions,
    });
  }

  async send(requestOptions: {}) {
    const { url, data, method, notify = true } = requestOptions;

    show_loader();

    const options: RequestInit = {
      ...requestOptions.ajaxOptions,
      method,
    };

    let fullUrl;

    options.headers = {
      ...options.headers,
      Accept: "application/json",
      Authorization: `Bearer ${$user.getToken()}`,
    };

    fullUrl = this.getUrl(url);

    if (options.method === "GET") {
      let queryString = new URLSearchParams(data);
      fullUrl += `?${queryString}`;
    } else if (!(data instanceof FormData)) {
      options.body = JSON.stringify(data);
      options.headers["Content-Type"] = "application/json";
    } else {
      options.body = data;
    }

    let res: ResponseType = {
      data: [],
      message: "",
      status_code: null,
    };

    try {
      const response = await fetch(fullUrl, options);
      if (response.status === 200) {
        res = await response.json();
        const { status_code, message } = res;
        if (options.method !== "GET" && notify) {
          this.notify({
            message,
            type: status_code === 200 ? "success" : "error",
          });
        }
      } else if (response.status === 500) {
        res = await response.json();
      } else if (response.status === 400) {
        res = await response.json();
        this.notify({
          message: res.message,
          type: "error",
        });
      } else if (response.status === 401) {
        res = await response.json();
        this.notify({
          message: res.message,
          type: "error",
        });
      } else {
        throw new Error(`${response.status} : ${response.statusText}`);
      }
    } catch (e) {
      console.error(e);
      this.notify({
        message: e.message,
        type: "error",
      });
      throw e;
    } finally {
      hide_loader();
    }

    const { status_code } = res;

    if (status_code !== 200) throw res;

    return res;
  }
}

export const $crud = new CrudFactory();
