export const AppName = "Viral Admin";
export const languages = [
  {
    local: "en",
    name: "English",
  },
  {
    local: "ar",
    name: "Arabic",
  },
];

export const currency = " KWD";

// Dev or Prod
// export const baseUrl = 'BASE_URL'
// export const loginFoodUrl = 'LOGIN_FOOD_URL'
// export const loginEcomUrl = 'LOGIN_ECOM_URL'
// export const PaymentSuccessUrl = "PAYMENT_SUCCESS_URL";
// export const PaymentFailUrl = "PAYMENT_FAIL_URL";
// export const surl = "SURL";
// export const termsUrl = "TERMS_URL";

// prod
// export const baseUrl = 'https://www.zofido.com'
// export const loginFoodUrl = 'https://www.zofido.com/seller'
// export const loginEcomUrl = 'https://www.zofido.com/ecom'
// export const PaymentSuccessUrl = "https://www.zofido.com/seller/#/payment-success";
// export const PaymentFailUrl = "https://www.zofido.com/seller/#/payment-fail";
// export const surl = "https://pg.bookeey.com/internalapi/api/payment/paymentstatus";
// export const termsUrl = "https://zofido.com/term-and-conditions";

// Dev
// export const baseUrl = "http://3.23.223.86";
export const baseUrl = "https://sellerapi.dev.zofido.com";
// export const loginFoodUrl = 'http://zofidoadmin.viralfood.live'
export const loginFoodUrl = "https://seller.dev.zofido.com";
export const loginEcomUrl = "http://3.23.223.86/ecom";
export const surl = "https://demo.bookeey.com/portal/paymentSuccess";
export const termsUrl = "https://dev.zofido.com/term-and-conditions";
// export const PaymentSuccessUrl = "https://zofidoadmin.viralfood.live/#/payment-success";
// export const PaymentFailUrl = "https://zofidoadmin.viralfood.live/#/payment-fail";
export const PaymentSuccessUrl =
  "https://seller.dev.zofido.com/#/payment-success";
export const PaymentFailUrl = "https://seller.dev.zofido.com/#/payment-fail";
export const CountryId = "5f6079517bf55a2f11bf67aa";

// redux actions
export const TOGGLE_DRAWER = Symbol("TOGGLE_DRAWER");
export const TOGGLE_SIDE_NAV = Symbol("TOGGLE_SIDE_NAV");
export const SET_ACTIVE_STATE = Symbol("SET_ACTIVE_STATE");
export const SET_TITLE = Symbol("SET_TITLE");
// export const customerSocketUrl = "https://3.23.223.86:9003/"
export const customerSocketUrl = "https://seller.devapi.zofido.com/";
// redux actions
