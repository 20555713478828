export const Arabic = {
  open: "مفتوح",
  close: "مغلق",
  busy: "مشغول",
  upgrade_plan: "طور باقتك",
  monthy_sales: "مبيعات الشهرية",
  revenue: "الايرادات",
  dashboard: "لوحة التحكم",
  orders: "الطلبات",
  products: "المنتجات",
  customers: "العملاء",
  store_sales: "مبيعات المتجر",
  order_volume: "حجم الطلب",
  recent_order: "النظام الجديد",
  cost_high_to_low: 'التكلفة ( الاعلى الى الاقل ) ',
  cost_low_to_high: 'التكلفة ( الاقل الى الاعلى )',
  set_status: "تعيين الحالة",
  date: "تاريخ",
  order_number: "رقم الأمر",
  details: "تفاصيل",
  amount: "السعر",
  serial_number: "رقم سري",
  name: "اسم",
  item: "بند",
  quantity: "كمية",
  new_customers: " العملاء جديد",
  active_user: "مستخدم نشط",
  most_used_discount_coupon: "قسيمة الخصم الأكثر استخدامًا",
  most_selling: "الأكثر مبيعًا",
  customer_enquiries: "استفسارات العملاء",
  reports: "التقارير",
  marketing: "التسويق",
  my_store: "متجري",
  order_management: "ادارة الطلبات",
  payments: "المدفوعات",
  category_management: "ادارة المجموعات",
  add_on: "اضافات",
  removable: "قابل للإزالة",
  option_types: "انواع الخيارات",
  options: "الخيارات",
  sales_reports: "تقارير مبيعات",
  payment_reports: "تقارير المدفوعات",
  coupons: "كوبونات",
  newsletter: "اشتراكات الايميل",
  manage_users: "ادارة حسابات الموظفين",
  google_analytics: "تحليل جوجل",
  chat_script: "الدردشه الكتابيه",
  profile: "الملف الشخصي",
  cms_pages: "ادارة المحتوى",
  faq: "الاسئلة الشائعة",
  store_settings: "اعدادات المتجر",
  email_permissions: "إذن البريد الإلكتروني",
  emails: "Emails",
  home_banners: "بانر الصفحة الريئسية",
  branches: "الفروع",
  my_refferals: "مسوقين المتجر",
  delivery_charges: "رسوم التوصيل",
  order_list: "لائحة الطلبات",
  number: "رقم",
  method: "طريقة",
  customer_name: "اسم الزبون",
  promo: "الترويجي",
  paid_amount: "المبلغ المدفوع",
  date_and_time: "التاريخ و الوقت",
  status: "الحالة",
  action: "عمل",
  search: "بحث",
  filter_by: "مصنف بواسطة",
  sort_by: "ترتيب حسب",
  pick_up: "امسك",
  delivery: "توصيل",
  expiry_alert: "تنبيه انتهاء الصلاحية",
  now: "الآن",
  schedule: "جدول",
  pending: "قيد الانتظار",
  all: "الكل",
  confirm: "تؤكد",
  preparing: "خطة",
  cancelled: "ألغيت",
  dispatch: "إيفاد",
  delivered: "تم التوصيل",
  return: "إرجاع",
  refund: "إعادة مال",
  incomplete: "غير مكتمل",
  payment_methods: "طرق الدفع",
  billing_details: "تفاصيل الفاتورة",
  total_payment: "المبلغ الإجمالي",
  loading_data: "تحميل البيانات",
  building: "بناء",
  street: "شارع",
  block: "منع",
  add_new_item: "أضف أداة جديدة",
  image: "صورة",
  product_name: "اسم المنتج",
  product_options: "خيارات المنتج",
  price: "السعر",
  active_inactive: "نشيط/غير نشط",
  no_match_record_found: "لم يتم العثور على سجل تطابق",
  no_record_found: "لا يوجد سجلات",
  update_product_order_number: "تحديث رقم طلب المنتج",
  cancel: "إلغاء",
  save: "حفظ",
  menu_category: "قائمة طعام/الفئة",
  active: "نشيط",
  inactive: "غير نشط",
  all_categories: "جميع الفئات",
  add_new_category: "إضافة فئة جديدة",
  removable: "قابل للإزالة",
  categories: "التصنيفات",
  add_new: "اضف جديد",
  created_at: "أنشئت في",
  removable_items: "العناصر القابلة للإزالة",
  addon_list: "أضف إلى القائمة",
  guest: "زائر",
  registered: "مسجل",
  phone_number: "رقم الهاتف",
  email: "البريد الإلكتروني",
  type: "نوع",
  message: "رسالة",
  from: "من عند",
  to: "إلى",
  today_sales: "مبيعات اليوم",
  highest: "الأعلى",
  today: "اليوم",
  sales: "مبيعات",
  list_of_coupons: "قائمة القسائم",
  toggle_password_visibility: "تبديل رؤية كلمة المرور",
  caption_table: "جدول التسمية التوضيحية",
  number_code: "رمز الرقم",
  start_date: "تاريخ البدء",
  end_date: "تاريخ الانتهاء",
  edit: "تعديل",
  delete: "حذف",
  email_list: "قائمة البريد الالكتروني",
  manage_users: "ادارة المستخدمين",
  areas: "المناطق",
  charges: "شحنة",
  delivery_type: "نوع التوصيل",
  referral_users_list: "قائمة مستخدمي الإحالة",
  referral_users: "Referral Users",
  registered_user: "مستخدم مسجل",
  registered_user_phone_number: "رقم هاتف المستخدم المسجل",
  commodity_amount: "كمية السلع",
  store_details: "تفاصيل المتجر",
  delivery_methods: "طرق التوصيل",
  email_permissions_for: "أذونات البريد الإلكتروني لـ:",
  scheduling: "الجدولة",
  third_party_shipping: "شحن طرف ثالث",
  main_setting: "الإعداد الرئيسي",
  store_status: "حالة المتجر",
  publish_status: "حالة النشر",
  publish: "ينشر",
  change_fav_icon: "تغيير أيقونة المفضلة",
  withhold: "حجب",
  enable: "ممكن",
  disable: "تعطيل",
  featured_products: "منتجات مميزة",
  best_seller_products: "أفضل المنتجات مبيعًا",
  mention_the_complete_link_eg: "اذكر مثال الارتباط الكامل",
  select_store_primary_color: "حدد مخزن اللون الأساسي",
  select_store_secondary_color: "حدد مخزن اللون الثانوي",
  select_store_button_color: "حدد لون زر المتجر",
  enable_disable_product_visibility: "ممكن/تعطيل رؤية المنتج",
  social_links: "روابط اجتماعية",
  change_select: "يتغيرون/تحديد",
  profile_information: "معلومات الملف الشخصي",
  mobile: "التليفون المحمول",
  affiliation_code: "كود الانتساب",
  change_profile_image: "تغيير صورة الملف الشخصي",
  logout: "تسجيل خروج",
  change_password: "غير كلمة السر",
  mention_the_complete_link_eg: "اذكر مثال الارتباط الكامل",
  add: "أضف",
  other_settings: "اعدادات اخرى",
  name_in_english: "الاسم باللغة الإنجليزية",
  name_in_arabic: "الاسم بالعربي",
  slogan_in_english: "شعار باللغة الإنجليزية",
  slogan_in_arabic: "الشعار بالعربية",
  contact_number: "رقم الاتصال",
  address: "عنوان",
  address_line_1: "خط عنوان",
  landmark: "معلم معروف",
  country: "بلد",
  location_map_url: "رابط خريطة الموقع",
  enable_social_login: "تمكين تسجيل الدخول الاجتماعي",
  google_client_id: "معرف عميل google",
  facebook_client_id: "معرف عميل الفيسبوك",
  store_time_slots: "فتحات تخزين الوقت",
  list_of_faq: "قائمة الأسئلة الشائعة",
  question: "سؤال",
  answer: "إجابة",
  delivery_time_in_english: "وقت التوصيل (بالانجليزي)",
  delivery_time_in_arabic: "وقت التوصيل بالعربي",
  new_products: "منتجات جديدة",
  minimum_order_price: "الحد الادنى للطلب",
  facebook_link: "رابط الفيسبوك",
  instagram_link: "رابط الانستقرام",
  snapchat_link: "رابط السناب جات",
  twitter_link: "رابط تويتر",
  whatsapp_number: "رقم الواتز اب",
  order_details: "تفاصيل الطلب",
  email_invoice: "فاتورة البريد الإلكتروني",
  print_invoice: "فاتورة طباعة",
  dispatch: "إيفاد",
  update: "تحديث",
  customer_information: "معلومات العميل",
  gender: "جنس",
  registered_date: "تاريخ التسجيل",
  order_amount: "كمية الطلب",
  delivery_details: "تفاصيل التسليم",
  delivery_status: "حالة التسليم",
  delivery_company: "شركة توصيل",
  delivery_date: "تاريخ التسليم او الوصول",
  delivery_time: "موعد التسليم",
  delivery_area: 'منطقة التسليم',
  note: "ملحوظة:",
  pickup_details: "تفاصيل الالتقاط",
  copy_location: "نسخ الموقع",
  shop_name: "اسم المحل",
  pickup_status: "حالة الالتقاط",
  picked_by: "بيك اب",
  self: "الذات",
  price: "السعر",
  house_number: "رقم الدار",
  delivery_partner_charge: "رسوم شريك التوصيل",
  apartment_number: "رقم الشقة",
  address_line_1_in_english: "العنوان بالانجليزي",
  address_line_1_in_arabic: "العنوان بالانجليزي",
  landmark_in_english: "العنوان بالانجليزي",
  landmark_in_arabic: "معلم معروف بالعربي",
  country_in_english: "الدولة بالانجليزي",
  country_in_arabic: "الدولة بالعربي",
  location_map_url: "رابط خريطة الموقع",
  enable_social_login: "تمكين تسجيل الدخول الاجتماعي",
  kurbi_vendor: "ايميل لشركة اوقو",
  updating: "...التحديث",
  removal: "إزالة",
  extra: "إضافي",
  notes: "ملاحظات",
  list_of_faq: "قائمة الأسئلة الشائعة",
  title: "عنوان",
  description: "وصف",
  //after pull
  plan_start_date: ':تاريخ بدء الخطة',
  product_management: 'ادارة المنتج',
  previous_balance_amount: ':مبلغ الرصيد السابق',
  total_payable: ':إجمالي المدفوعات',
  select_payment_method: ':اختار طريقة الدفع',
  by_proceeding_i_agree: 'بالمتابعة أوافق على Viral',
  terms_and_conditions: '.الأحكام والشروط',
  if_you_have_selected_same_plan: '.إذا حددت نفس الخطة ، فستبدأ خطتك من تاريخ انتهاء صلاحية الخطة الحالية* "',
  if_you_have_selected_new_plan: '.إذا كنت قد حددت خطة جديدة ، فستبدأ خطتك الجديدة من اليوم الحالي*',
  proceed: 'تقدم',
  sorry_your_payment_failed: 'آسف ، فشل الدفع الخاص بك',
  your_transaction_is_failed: 'فشلت معاملتك أو ألغيت. يرجى التحقق من التفاصيل وحاول مرة أخرى في وقت لاحق.',
  go_to_plan: 'اذهب إلى الخطة',
  payment_failed: 'عملية الدفع فشلت',
  your_transaction_is_failed: 'فشلت معاملتك أو ألغيت. يرجى التحقق من التفاصيل وحاول مرة أخرى في وقت لاحق.',
  payment_success_plan_updated: 'تم تحديث خطة الدفع بنجاح',
  thank_you_for_updating_plan: '.شكرا لك على تحديث الخطة',
  payment_reference_no: ':رقم مرجع الدفع',
  go_to_dashboard: 'الذهاب إلى لوحة القيادة',
  your_current_plan_expired: 'انتهت خطتك الحالية. تجديد / تحديث الخطة',
  new_plan_summary: 'ملخص الخطة الجديدة',
  //visit store
  visit_store: 'قم بزيارة المعرض او المتجر',
  //side nav
  are_you_sure_to_change_store_status: 'هل أنت متأكد من تغيير حالة المتجر إلى:',
  //reset password
  reset_password: 'إعادة تعيين كلمة المرور',
  login_into_dashboard: '...تسجيل الدخول إلى لوحة التحكم الرجاء الانتظار',
  //product images
  product_images: 'صورة المنتج',
  product_image_size: 'يجب أن يكون حجم صورة المنتج: 582 بكسل. * ارتفاع 375 بكسل',
  //print invoice
  print: 'طباعة',
  invoice_to: 'فاتورة إلى',
  invoice: 'فاتورة',
  total: 'مجموع',
  addons: '"اضافات:"',
  subtotal: 'مبلغ اجمالي',
  grand_total: 'المجموع الإجمالي',
  thank_you: '!شكرا لك',
  invoice_was_created: '.تم إنشاء الفاتورة على جهاز كمبيوتر وهي صالحة بدون التوقيع والختم',
  //permissions
  dont_have_permission: `ليس لديك إذن`,
  please_contact_admin_permission: 'يرجى الاتصال بالمسؤول للحصول على إذن للوصول إليه',
  location_copied: 'تم نسخ الموقع!',
  location_not_available: 'الموقع غير متوفر',
  //login
  loading: '..جاري تحميل',
  seller_login: 'تسجيل دخول البائع',
  select_your_store_type: 'حدد نوع المتجر الخاص بك',
  food: 'طعام',
  e_commerse: 'التجارة الإلكترونية',
  sign_in: 'تسجيل الدخول',
  signing_in: '..تسجيل الدخول',
  logo: 'شعار',
  //extend plan
  do_you_extend_plan: 'هل تريد تمديد هذه الخطة',
  current_plan_information: 'معلومات الخطة الحالية',
  plan_name: ':اسم الخطة',
  business_type: ':نوع العمل',
  selected_country: ':الدولة المختارة',
  expiry_date: ':تاريخ انتهاء الصلاحية',
  duration: 'لوحة التحكم',
  search_plan: 'خطة البحث',
  extend_plan: 'تمديد الخطة',
  plan_details: 'تفاصيل الخطة',
  //change password
  new_password: 'كلمة مرور جديدة',
  clear: 'صافي',
  submit: 'إرسال',
  forgot_password: 'هل نسيت كلمة السر؟',
  back_to_login_page: 'العودة إلى صفحة تسجيل الدخول',
  sending: '...إرسال',
  //addons details
  add_on_details: 'تفاصيل الإضافي',
  add_item: 'اضافة عنصر',
  do_you_delete_addons: 'هل تريد حذف هذا المنتج الإضافي؟',
  do_you_delete_image: 'هل تريد حذف هذه الصورة؟',
  add_on_image: 'الصورة الإضافية',
  delete_image: 'حذف صورة',
  choose_images: 'اختر صوره',
  previous: 'السابق',
  finish: 'انهاء',
  validation_failed: 'فشل التحقق من الصحة',
  validity: 'صلاحية',
  //dialog
  info_image: 'معلومات الصورة',
  //contact us
  do_you_delete_enquiry: 'هل تريد حذف هذا الاستعلام؟',
  contact_details: 'تفاصيل التواصل',
  //branches
  do_you_delete_branch: 'هل تريد حذف هذا الفرع؟',
  no_branch_found: '!لم يتم العثور على فرع',
  add_store_details: 'إضافة تفاصيل المتجر',
  latitude: 'خط العرض',
  longitude: 'خط الطول',
  map_url: "رابط خريطة الموقع",
  adding_branch: '..اضافة فرع',
  add_branch: 'إضافة فرع',
  updating_branch: '..تحديث الفرع',
  update_branch: 'فرع التحديث',
  //home banner
  do_you_delete_banner: 'هل تريد حذف هذا البانر؟',
  banner_image: 'صورة البانر',
  no_banners_found: '!لم يتم العثور على لافتات',
  please_add_banner_before_submit: 'الرجاء إضافة بانر قبل التقديم',
  web_banner: 'بانر للويب',
  banner_image_size: 'يجب أن يكون حجم صورة البانر بارتفاع 413 بكسل وعرض 1920 بكسل',
  change_banner_image: 'تغيير صورة الشعار',
  remove_banner_image: 'إزالة صورة الشعار',
  tag_line_in_english: 'سطر العلامة (بالانجليزي)',
  tag_line_in_arabic: 'سطر العلامة (بالعربية)',
  mobile_banner: 'بانر للجوال',
  mobile_banner_size: 'يجب أن يكون حجم صورة البانر 1024 بكسل و 773 بكسل',
  //delivery charges
  set_delivery_charges: 'تحديد رسوم التوصيل',
  paid: 'مدفوع',
  free: 'مجاني',
  governorate: 'المحافظة',
  edit_delivery_charges: 'تعديل سعر التوصيل',
  //faq
  do_you_delete_faq: 'هل تريد حذف هذه الأسئلة الشائعة',
  this_field_is_required: 'هذه الخانة مطلوبه',
  please_fill_required_fields: 'يرجى ملء جميع الحقول المطلوبة',
  faq_details: 'تفاصيل التعليمات',
  question_in_english: '*السؤال باللغة الانجليزية',
  question_in_arabic: '*السؤال باللغة العربية',
  answer_in_english: '*أجب باللغة الإنجليزية',
  answer_in_arabic: '*الجواب بالعربية',
  //cms pages
  do_you_delete_cms: 'هذه؟ CMS هل تريد حذف صفحة',
  page_details: 'تفاصيل الصفحة',
  page_title_in_english: '*عنوان الصفحة باللغة الانجليزية',
  page_title_in_arabic: '*عنوان الصفحة باللغة العربية',
  meta_keyword: 'ميتا الكلمات الدالة',
  meta_description: 'ميتا وصف العلامة',
  meta_title: 'ميتا عنوان العلامة',
  content_in_english: '*المحتوى باللغة الانجليزية',
  content_in_arabic: '*المحتوى باللغة العربية',
  featured_image: 'صورة العرض',
  banner: 'لافتة',
  change: 'يتغيرون',
  change_featured_image: 'تغيير الصورة المميزة',
  //chat script
  update_chat_script: 'تحديث كود اللايف جات',
  //google analytics
  update_google_analytic_script: 'تحديث كود قوقل اناليتكس',
  script_code: 'script كود ال',
  //scheduling
  store_timing: 'توقيت المتجر',
  add_time_slots: 'أضف فترات زمنية',
  //users
  you_are_not_authorised: 'أنت غير مخول للوصول إليه',
  do_you_delete_user: 'هل تريد حذف هذا المستخدم؟',
  set_permissions: 'تعيين أذونات',
  view_details: 'عرض التفاصيل',
  passwords_does_not_match: 'كلمة السر غير متطابقة',
  add_user_information: 'إضافة معلومات المستخدم',
  username: 'اسم االمستخدم',
  password: 'كلمه السر',
  confirm_password: 'تأكيد كلمة المرور',
  edit_user_information: 'تحرير معلومات المستخدم',
  view: 'عرض',
  'Orders': 'الطلبات',
  'Dashboard': 'لوحة التحكم',
  'Payments': "المدفوعات",
  'category': 'الفئة',
  'Option Types': "انواع الخيارات",
  'Options': "الخيارات",
  "Removables": "قابل للإزالة",
  "Category Management": "ادارة المجموعات",
  'Products': 'منتج',
  'addon': 'اضافه',
  "Reports": "التقارير",
  'Customers': "العملاء",
  'Customer Enquiries': 'استفسارات العملاء',
  'Sales Reports': 'تقارير مبيعات',
  "Payment Reports": "تقارير المدفوعات",
  "Marketing": "التسويق",
  "My Store": "متجري",
  "Store Settings": "اعدادات المتجر",
  "Manage Users": "ادارة حسابات الموظفين",
  "Scheduling": "الجدولة",
  "Google Analytic": "تحليل جوجل",
  "Chat Script": "الدردشه الكتابيه",
  "Profile": "الملف الشخصي",
  "CMS Pages": "ادارة المحتوى",
  "FAQ": "الاسئلة الشائعة",
  'Coupons': 'كوبونات',
  'Newsletter': 'اشتراكات الايميل',
  'manage_cms': 'إدارة نظام إدارة المحتوى',
  'manage_faq': 'إدارة التعليمات',
  'store': 'محل',
  'Home Banners': 'إدارة البانر',
  "Background": 'معرفتي',
  'Branches': 'الفروع',
  "My Referrals": "مسوقين المتجر",
  "Plan Details": 'تفاصيل الخطة',
  "Store Status": "حالة المتجر",
  'Delivery Charges': 'رسوم التوصيل',
  'Order Management': "ادارة الطلبات",
  //store settings
  store_name: 'اسم المتجر',
  only_files_allowed: 'يسمح بملفات (JPG , PNG , JPEG ) فقط',
  only_ico_allowed: ' فقط .ico تسمح ملفات',
  please_add_atleast_one: '.الرجاء إضافة خانة زمنية واحدة على الأقل',
  please_add_setting_details: '.الرجاء إضافة تفاصيل الإعداد قبل الإرسال',
  logo_size: 'حجم الشعار: ارتفاع 70 بكسل وعرض 70 بكسل',
  fav_icon_size: 'حجم فافيكون: ارتفاع 25 بكسل وعرض 25 بكسل',
  change_store_icon: 'تغيير أيقونة المتجر',
  contact_ogo_to_create: 'Ogo اتصل بشركة أوغو لإنشاء حساب التاجر الخاص بك وتقديم البريد الإلكتروني للفرع المعتمد هنا للارتباط بـ',
  color_info: 'معلومات اللون',
  primary_color: ':لون أصلي',
  header_color_footer_color: 'لون الرأس ، لون التذييل (الضميمة) ، تمييز التسليم / الاستلام',
  secondary_color: ':اللون الثانوي',
  prices_in_menu: 'الأسعار في القائمة ، أزرار التجزئة (في تفاصيل العنصر) ، إضافة إلى السلة ، القائمة أدناه ، + و - في صفحة عربة التسوق ، + و - في صفحة تفاصيل المنتج ، أسماء الفئات ، إضافة العنوان + ، وضع علامة ، تتبع الطلب ، احصل على المساعدة (تأكيد الصفحات) المفضلة - سعر الأصناف',
  button_color: ':لون الزر',
  continue_shoppping_checkout: 'متابعة التسوق ، الخروج ، خط الحدود حول التاريخ والأوقات ، التالي ، التقديم ، إرسال الطلب ، المتابعة (تأكيد الطلب) تسجيل الدخول ، التسجيل أيضًا الروابط الموجودة أسفل علامات التبويب تسجيل الدخول والتسجيل',
  profile_change: 'الطلبات السابقة,تغيير الملف الشخصي - زر إعادة الترتيب',
  contact_us_submit: 'اتصل بنا - إرسال',
  set_time_slot: 'ضبط فترة زمنية',
  opening_time: 'وقت مفتوح',
  close_time: 'وقت الاغلاق',
  google_client_id: "معرف عميل google",
  facebook_client_id: "معرف عميل الفيسبوك",

  //coupons
  add_coupon_details: 'إضافة تفاصيل القسيمة',
  coupon_name_in_english: '*اسم الكوبون بالانقليزي',
  coupon_name_in_arabic: '*اسم الكوبون بالعربي',
  coupon_code: '*الكود',
  coupon_usage_limit: '*عدد استخدام الكود',
  coupon_type: 'نوع الكود',
  amount_percentage: '* % المبلغ / النسبة',
  start_date_time: '*تاريخ البدء',
  end_date_time: '*تاريخ الانتهاء',
  minimum_order_amount: '*اقل مبلغ للطلب',
  edit_coupon_details: 'تعديل تفاصيل الكوبون',
  //customers
  delete_customer: 'حذف العميل',
  customer_details: 'بيانات العميل',
  basic_info: 'معلومات',
  area: 'منطقة',
  house_flat_no: ':رقم المنزل / الشقة',
  avenue: 'السبيل',
  additional_direction: 'اتجاه إضافي',
  back: 'العودة',
  export_to_excel: 'التصدير للاكسل',
  //add product
  in_process: '..تحت المعالجة',
  save_details: 'احفظ التفاصيل',
  product_details: 'تفاصيل المنتج',
  description_in_english: '*الوصف باللغة الانجليزية',
  description_in_arabic: '*الاسم بالعربية',
  category: 'الفئة',
  item_status: 'حالة الكود',
  item_type: 'نوع العنصر',
  new: 'الجديد',
  best_sellers: 'أفضل البائعين',
  featured: 'متميز',
  is_discountable: 'قابل للخصم',
  normal_price: 'السعر العادي*',
  selection_price: 'سعر الاختيار*',
  special_price: 'سعر خاص*',
  remove: 'إزالة',
  add_more: 'أضف المزيد',
  variants: 'المتغيرات',
  title_in_english: 'العنوان باللغة الإنجليزية',
  title_in_arabic: 'العنوان بالعربية',
  option_type: 'انواع الخيارات',
  single: 'أعزب',
  multiple: 'مضاعف',
  discount: '% خصم',
  required: 'مطلوب',
  //editProduct
  publish_item: 'نشر العنصر',
  //products
  price_on_selection: 'السعر عند الاختيار',
  filter_by_category: 'تصفية حسب المجموعة',
  //category management
  save_category: 'حفظ الفئة',
  //removal products
  please_wait: '...انتظر من فضلك',
  save_item: 'حفظ العنصر',
  //payments
  cod: 'الدفع عند الاستلام',
  no_payments_found: 'لم يتم العثور على دفع',
  //orders
  no_order_found: 'لم يتم العثور على طلب!',
  //option types
  edit_option_type: 'تحرير نوع الخيار',
  add_option_type: 'أضف نوع الخيار',
  //product options
  edit_option: 'خيار التحرير',
  add_option: 'إضافة خيار',
  //validations
  are_you_sure: 'هل أنت متأكد؟',
  do_you_delete_order: 'هل تريد حذف هذا الطلب؟',
  do_you_delete_option_type: 'هل تريد حذف نوع الخيار هذا؟',
  do_you_delete_option: 'هل تريد حذف هذا الخيار؟',
  do_you_delete_this: 'هل تريد حذف هذا؟',
  do_you_delete_category: 'هل تريد حذف هذا القسم؟',
  do_you_delete_product: 'هل تريد حذف هذا المنتج؟',
  do_you_delete_email: 'هل تريد حذف هذا البريد الإلكتروني؟',
  english_name_is_required: 'الاسم الانجليزي مطلوب',
  arabic_name_is_required: 'الاسم العربي مطلوب',
  english_category_is_required: 'مطلوب اسم فئة اللغة الإنجليزية',
  arabic_category_required: 'مطلوب اسم الفئة العربية',
  do_you_delete_customer: 'هل تريد حذف هذا العميل؟',
  do_you_delete_coupon: 'هل تريد حذف هذه القسيمة؟',
  yes: 'نعم',
  no: 'لا',
  cash: "السيولة النقدية",
  add_product: 'أضف منتج',
  varient_option_deleted: 'تم حذف الخيار المتغير. انشر للتأكيد',
  varient_deleted: 'تم حذف المتغير. انشر للتأكيد',
  atlease_one_option_required: 'مطلوب خيار واحد على الأقل',
  state: "الولاية",
  price_per_item: "السعر للسلعة الواحدة",
  pickup_address: "عنوان بيك اب",
  payment_commission: "عمولة الدفع",
  debit_card: "بطاقات الخصم",
  credit_card: "بطاقة الائتمان",
  commission_type: "نوع العمولة",
  TopSellingProducts: "المنتجات الأكثر مبيعًا",
  "Clear Filter": "مسح التصفية",
  "Product varients": "متغيرات المنتج",
  "Order Id": "رقم التعريف الخاص بالطلب",
  "Discount per item": "خصم لكل عنصر",
  "Profit Price": "سعر الربح",
  profit_reports: 'تقارير الربح',
  "Governorate/State": "المحافظة / الولاية",
  floor_no: "رقم الطابق",
  address_name: "اسم عنوان",
  building_number: "رقم المبنى",
  address_2: "عنوان 2 ",
  address_1: "عنوان 1",
  office: 'مكتب',
  "Delivery Partner Charge": "رسوم شريك التوصيل",
  please_select_payment_gateway: "الرجاء تحديد بوابة الدفع",
  please_agree_to_zofido_terms_and_conditions: "الرجاء الموافقة على الشروط والأحكام لفايرال",
  average_order_value: "متوسط ​​قيمة الطلب",
  shop: "Shop",
  users: "Users",
  monthly_sales: "مبيعات الشهرية",
  third_party_services:"Third Party Services",
  third_party:"Third Party",
  add_store_timing:"Add Store Timing",
  edit_branch:"Edit Branch",
  add_home_banners:"Add Home Banners"
}

