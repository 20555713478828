import * as React from "react";
import { useCallback, useEffect, useState } from "react";

import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import classNames from "classnames";
import { UIRouterConsumer, UISref } from "@uirouter/react";
import { useDispatch, useSelector } from "react-redux";
import { SET_ACTIVE_STATE, TOGGLE_DRAWER, TOGGLE_SIDE_NAV } from "./constants";
import { find } from "lodash";
import { ChevronDown, ChevronUp } from "react-feather";
import { $user, useCurrentUser } from "./Services/UserFactory";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { $crud } from "./Services/CrudFactory";
import LanguageContext from "./LanguageContext/LanguageContext";
import { Arabic } from "./components/Arabic/Arabic";
import Dashboard from "./utils/icons/Dashboard.js";
import Products from "./utils/icons/Products.js";
import { $state } from "./router";
import AnnouncementTwoTone from "@material-ui/icons/AnnouncementTwoTone";
import BarChartTwoTone from "@material-ui/icons/BarChartTwoTone";
import CategoryTwoTone from "@material-ui/icons/CategoryTwoTone";
import DashboardTwoTone from "@material-ui/icons/DashboardTwoTone";
import GroupTwoTone from "@material-ui/icons/GroupTwoTone";
import LibraryBooksTwoTone from "@material-ui/icons/LibraryBooksTwoTone";
import StorefrontTwoTone from "@material-ui/icons/StorefrontTwoTone";
import TvTwoTone from "@material-ui/icons/TvTwoTone";
import { Box, Divider, Drawer, Paper, Typography } from "@material-ui/core";

// import { withTranslation, useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 250,
    [theme.breakpoints.down("sm")]: {
      // backgroundImage: "linear-gradient(135deg, #22CEE5, #AE29F1)",
      // zIndex:"999"
    },
  },
  containerShrink: {
    width: 62,
  },
  containerHide: {
    display: "none",
  },

  logoutButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
  },

  logoContainer: {
    background: "transparent",
    height: 64,
  },
  menuContainer: {
    background: "transparent",
    color: theme.palette.primary.contrastText,
    overflowY: "auto",
  },
  activeLink: {
    // backgroundColor: theme.palette.secondary.main,
    // color: theme.palette.secondary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary[300],
    },
    // borderLeft:"5px solid"
    // borderLeft: "5px solid #0743C9",
    // color: "#0743C9"
    background: "#f5f5f5",
    borderRadius: "8px",
  },
  activeSideLink: {
    "&:hover": {
      background: "#00a99d",
    },
    // borderRight: "5px solid #0743C9",
    // color: "#0743C9",
    background: "#f5f5f5",
    borderRadius: "8px",
  },
  nestedLink: {
    borderRadius: "8px",
    marginTop: "10px",
    "&:first-child": {
      marginTop: "0px",
    },
    "&:hover": {
      background: "#f5f5f5",
    },
  },
  nestedLinkBottom: {
    borderRadius: 8,
    // borderBottom: "2px solid #f5f5f5",
    // padding: "0.2em",
  },
  sideNavItemClicked: {
    borderLeft: "5px solid #0743C9",
    color: "#0743C9",
  },
  mainContainer: {
    // padding: "0px 16px",
    // "@media print": {
    //   display: "none",
    // },
    borderRight: "1px solid #f1f1f1",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.04)",
  },
  drawerIcon: {
    fontSize: 24,
    color: theme.palette.text.secondary,
  },
  sideNavHeader: {
    borderBottom: "1px solid #f1f1f1",
    borderRadius: 0,
    height: 81,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
    // display: "none",
  },
  sideNavHeaderLogo: {
    letterSpacing: "1px",
    fontFamily: "Nunito !important",
    fontWeight: "800",
    fontSize: 35,

    // paddingBottom: 10,
  },
  drawerContainer: {
    padding: 10,
    paddingBottom: "80px",
  },
  sidebarLink: {
    background: "#00a99d",
  },
}));

const SideNav = ({ t }) => {
  // const { i18n } = useTranslation();
  //========Languange selector========//
  const { isArb, setIsArb } = React.useContext(LanguageContext);

  // const handleChange = (index) => {
  //     // i18n.changeLanguage(isArb ? "ar" : "en");
  //     isArb ? localStorage.setItem('switch', 1) : localStorage.setItem('switch', 2);
  //     setIsArb(!isArb);
  //     // window.location.reload(true);
  // };
  //==================================//
  const drawerOpen = useSelector((state) => state.drawerOpen);

  const sideNavOpen = useSelector((state) => state.sideNavOpen);

  const activeState = useSelector((state) => state.activeState);
  const get_setting = useSelector((state) => state.setting);
  const user = useCurrentUser();
  const classes = useStyles({});
  const [status, setStatus] = useState(null);
  const [plan, setPlan] = useState(user.plan_id);

  const [setting, setSetting] = useState(get_setting);

  useEffect(() => {
    if (!setting) {
      const sett = $user.getSetting() || {
        status: false,
        store_name: { en: "Store Name" },
        thumbnail_image_url:
          process.env.PUBLIC_URL + "/assets/images/default-logo.png",
      };
      setStatus(sett?.status);
      setSetting({
        store_name: {
          en: sett.store_name?.en,
        },
        thumbnail_image_url:
          process.env.PUBLIC_URL + "/assets/images/default-logo.png",
      });
    } else {
      setStatus(get_setting.status);
      setSetting({
        store_name: {
          en: get_setting.store_name?.en,
        },
        thumbnail_image_url:
          $user.getSetting()?.thumbnail_image_url !== null
            ? $user.getSetting()?.thumbnail_image_url
            : process.env.PUBLIC_URL + "/assets/images/default-logo.png",
      });
    }
  }, [get_setting]);

  const linkData = [
    {
      // label: t('dashboard'),
      labelAr: Arabic.dashboard,
      labelEn: "Dashboard",
      sref: "dashboard",
      open: true,
      lock: false,
      elementRef: null,
      icon: <DashboardTwoTone className={classes.drawerIcon} />,
    },

    {
      labelAr: Arabic.orders,
      labelEn: "Orders",
      sref: "sales",
      lock: false,
      icon: <LibraryBooksTwoTone className={classes.drawerIcon} />,

      links: [
        {
          labelAr: Arabic.order_management,
          labelEn: "Order Management",
          sref: "orders",
          lock: false,
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.payments,
          labelEn: "Payments",
          sref: "payments",
          lock: false,
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: "Incomplete Orders",
          labelEn: "Incomplete Orders",
          sref: "incompleteOrders",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
      ],
    },
    {
      labelAr: Arabic.products,
      labelEn: "Products",
      sref: "products",
      lock: false,
      icon: <CategoryTwoTone className={classes.drawerIcon} />,

      links: [
        {
          labelAr: Arabic.TopSellingProducts,
          labelEn: "Top Selling Products",
          sref: "topSellingProducts",
          open: true,
          elementRef: null,
        },
        {
          labelAr: Arabic.option_types,
          labelEn: "Option Types",
          lock: false,
          sref: "optionTypes",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },

        {
          labelAr: Arabic.options,
          labelEn: "Options",
          lock: false,
          sref: "productOptions",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.add_on,
          labelEn: "Removables",
          lock: false,
          sref: "removalProducts",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.category_management,
          labelEn: "Category Management",
          lock: false,
          sref: "categoryManagement",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.products,
          labelEn: "Product Management",
          lock: false,
          sref: "products",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
      ],
    },
    {
      labelAr: Arabic.customers,
      labelEn: "Customers",
      lock: false,
      // sref: "customers",
      open: false,
      elementRef: null,
      icon: <GroupTwoTone className={classes.drawerIcon} />,

      links: [
        {
          labelAr: Arabic.customer_enquiries,
          labelEn: "Customers",
          sref: "customers",
          lock: false,
          open: true,
          elementRef: null,
        },
        {
          labelAr: Arabic.customer_enquiries,
          labelEn: "Customer Enquiries",
          sref: "contactUs",
          lock: false,
          open: true,
          elementRef: null,
        },
      ],
    },

    {
      labelAr: Arabic.reports,
      labelEn: "Reports",
      lock: false,
      sref: "home",
      icon: <BarChartTwoTone className={classes.drawerIcon} />,

      links: [
        {
          labelAr: Arabic.sales_reports,
          labelEn: "Sales Reports",
          lock: !plan?.basic_reports?.value,
          sref: plan?.basic_reports?.value ? "saleReports" : "",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.payment_reports,
          labelEn: "Payment Reports",
          lock: !plan?.basic_reports?.value,
          sref: plan?.basic_reports?.value ? "paymentsReport" : "",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.payment_reports,
          labelEn: "Order Reports",
          lock: !plan?.basic_reports?.value,
          sref: plan?.basic_reports?.value ? "orderReport" : "",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: "Arabic.sales_reports",
          labelEn: "Profit Reports",
          lock: !plan?.basic_reports?.value,
          sref: plan?.basic_reports?.value ? "profitReport" : "",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
      ],
    },
    {
      labelAr: Arabic.marketing,
      labelEn: "Marketing",
      sref: "home",
      icon: <AnnouncementTwoTone className={classes.drawerIcon} />,

      links: [
        {
          labelAr: Arabic.coupons,
          labelEn: "Coupons",
          lock: !plan?.discount_codes?.value,
          sref: plan?.discount_codes?.value ? "coupons" : "",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.newsletter,
          labelEn: "Newsletter",
          lock: false,
          sref: "emailSubscription",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
      ],
    },
    {
      labelAr: Arabic.landing_page,
      labelEn: "Landing Page",
      sref: "home",
      lock: false,
      icon: <TvTwoTone className={classes.drawerIcon} />,

      links: [
        {
          labelAr: Arabic.home_banners,
          labelEn: "Landing Banners",
          lock: false,
          type: "main",
          sref: "manageBanner",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.most_selling,
          labelEn: "Most Selling",
          lock: false,
          sref: "mostSelling",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.gallery,
          labelEn: "Gallery",
          lock: false,
          sref: "galleryList",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.testimonials,
          labelEn: "Testimonials",
          lock: false,
          sref: "testimonials",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.promotions,
          labelEn: "Promotions",
          lock: false,
          sref: "promotions",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.blog,
          labelEn: "Blogs",
          lock: false,
          sref: "blogs",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
      ],
    },
    {
      labelAr: Arabic.my_store,
      labelEn: "My Store",
      sref: "home",
      lock: false,
      icon: <StorefrontTwoTone className={classes.drawerIcon} />,

      links: [
        {
          labelAr: Arabic.store_settings,
          labelEn: "Store Settings",
          lock: false,
          sref: "storeSettings",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: "Customize",
          labelEn: "Customize",
          lock: false,
          sref: "customManagement",
        },
        {
          labelAr: Arabic.third_party_services,
          labelEn: "Third Party Services",
          lock: false,
          sref: "thirdPartyServices",
        },

        {
          labelAr: Arabic.manage_users,
          labelEn: "Manage Users",
          lock: false,
          sref: "users",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.scheduling,
          labelEn: "Scheduling",
          lock: !plan?.scheduling?.value,
          sref: plan?.scheduling?.value ? "scheduling" : "",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },

        {
          labelAr: Arabic.profile,
          labelEn: "Profile",
          lock: false,
          sref: "profile",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.cms_pages,
          labelEn: "CMS Pages",
          lock: false,
          sref: "cms",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.faq,
          labelEn: "FAQ",
          lock: false,
          sref: "faq",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.delivery_charges,
          labelEn: "Delivery Charges",
          lock: false,
          sref: "deliveryCharges",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },

        {
          labelAr: Arabic.home_banners,
          labelEn: "Home Banners",
          lock: false,
          type: "home",
          sref: "manageBanner",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.branches,
          labelEn: "Branches",
          lock: false,
          sref: "storeBranches",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
        {
          labelAr: Arabic.my_refferals,
          labelEn: "My Referrals",
          lock: false,
          sref: "affiliateDetails",
          icon: (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/circle.svg"}
              size={20}
              alt={"circle"}
            />
          ),
        },
      ],
    },
  ];
  const [links, setLinks] = useState(linkData);

  const dispatch = useDispatch();

  const openDrawer = useCallback(
    () =>
      dispatch({
        type: TOGGLE_DRAWER,
        open: true,
      }),
    []
  );

  const setActiveState = useCallback((activeState) =>
    dispatch({
      type: SET_ACTIVE_STATE,
      activeState: activeState,
    })
  );

  const draweOpen = useSelector((state) => state.drawerOpen);

  const closeDrawer = useCallback(
    () =>
      dispatch({
        type: TOGGLE_DRAWER,
        open: false,
      }),
    []
  );

  const toggleCollapse = (index, state) => {
    setLinks((prevLinks) => {
      const links = [...prevLinks];
      const prevLink = prevLinks[index];
      links[index] = {
        ...prevLink,
        open: state !== undefined ? state : !prevLink.open,
      };
      return links;
    });
  };

  // useEffect(() => {
  //     return links;
  // }, [isArb]);

  const toggleSideNav = useCallback(
    (open) =>
      dispatch({
        type: TOGGLE_SIDE_NAV,
        sideNavOpen: open,
      }),
    []
  );

  let [storeStatusView, setStoreStatusView] = useState(false);
  let [storeStatusEdit, setStoreStatusEdit] = useState(false);

  const getPermissionsForStoreStatus = () => {
    if (user?.role === "SubAdmin") {
      user.permission.permissions.map((item) => {
        if (item.label === "Store Status" && item.action.view) {
          setStoreStatusView(true);
        }
      });
    }
  };

  const getPermissionsForStoreStatusEdit = () => {
    if (user?.role === "SubAdmin") {
      user.permission.permissions.map((item) => {
        if (item.label === "Store Status" && item.action.edit) {
          setStoreStatusEdit(true);
        }
      });
    }
  };

  useEffect(() => {
    getPermissionsForStoreStatusEdit();
    getPermissionsForStoreStatus();
  });

  useEffect(() => {
    if (activeState)
      setLinks((links) => {
        return links.map((link) => {
          if (!link.open && find(link.links, { sref: activeState.name })) {
            return {
              ...link,
              open: true,
            };
          } else {
            return link;
          }
        });
      });
  }, [activeState]);

  const changeStoreStatus = async (sts, e) => {
    await $crud.confirm({
      title: isArb ? Arabic.are_you_sure : "Are you sure?",
      textContent: isArb
        ? Arabic.are_you_sure_to_change_store_status
        : "Are you sure to change store status to:" + sts + "?",
      options: {
        ok: isArb ? Arabic.yes : "Yes",
        cancel: isArb ? Arabic.no : "No",
      },
    });

    await $crud.post("setting/update_store_status", { status: sts });
    const getSetting = setting;
    getSetting.status = sts;
    localStorage.setItem("setting", JSON.stringify(getSetting));
    setStatus(sts);
  };

  // useEffect(() => {
  //     links.map((link, index) => toggleCollapse(index, false))
  // }, [!sideNavOpen]);

  const userPermissions = {};
  if (user.role == "SubAdmin") {
    user.permission.permissions.map((dat) => {
      if (dat.action.view) {
        userPermissions[dat.label] = dat.action.view;
      }
    });
  }
  return (
    <UIRouterConsumer>
      {(router) => {
        const content = (
          <Grid
            item
            xs
            container
            direction="column"
            wrap="nowrap"
            className={classes.mainContainer}>
            {console.log(
              "set links ",
              window.location.hash.substring(1).split("/")[1]
            )}

            <Grid
              container
              direction="column"
              wrap="nowrap"
              item
              xs
              className={classNames(classes.menuContainer)}>
              <Grid
                component={List}
                disablePadding
                container
                direction="column"
                wrap="nowrap"
                item
                xs>
                {/* <Hidden mdUp>
                  <Grid
                    item
                    style={{
                      fontSize: 18,
                      textAlign: "left",
                      font: "normal normal normal 35px/58px Arial Rounded MT Bold",
                      letterSpacing: "2.5px",
                      color: "#0A44C9",
                      opacity: "1",
                    }}
                    className='pt-4 pb-3 pl-3 text-truncate'>
                    Viral
                  </Grid>
                </Hidden> */}

                <Box className={classes.sideNavHeader}>
                  <Typography
                    className={classes.sideNavHeaderLogo}
                    align="center"
                    variant="h5"
                    component={"h1"}
                    color="primary">
                    Viral
                  </Typography>
                </Box>

                <Grid item className={classes.drawerContainer}>
                  {links.map((link, index) => {
                    if (user.role == "Admin" || userPermissions[link.labelEn]) {
                      if (link.links) {
                        return (
                          <React.Fragment
                            key={isArb ? link.labelAr : link.labelEn}>
                            <ListItem
                              className="side-menu-item"
                              ref={(ref) => (link.elementRef = ref)}
                              button
                              onClick={() => {
                                toggleCollapse(index);
                                // setActiveState(link)
                              }}>
                              <Grid
                                item
                                xs
                                container
                                alignItems="center"
                                className="sideNavLink">
                                {link.icon}
                                {
                                  // sideNavOpen &&
                                  <span className="pl-2 side-menu-link-text">
                                    {isArb ? link.labelAr : link.labelEn}
                                  </span>
                                }
                                {link.lock ? (
                                  <i className="fa fa-lock ml-2"></i>
                                ) : (
                                  ""
                                )}
                              </Grid>
                              {
                                // sideNavOpen &&
                                link.links && (
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      style={{ width: "25px", height: "25px" }}
                                      size="small"
                                      color="inherit"
                                      onClick={() => {
                                        toggleCollapse(index);
                                      }}>
                                      {!link.open ? (
                                        <ChevronDown
                                          className="text-gray"
                                          width={20}
                                        />
                                      ) : (
                                        <ChevronUp
                                          className="text-gray"
                                          width={20}
                                        />
                                      )}
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                )
                              }
                            </ListItem>
                            {link.links && (
                              <>
                                <Collapse in={link.open}>
                                  {console.log("link open ", link?.links)}
                                  <List
                                    className="side-menu-with-child"
                                    // onClick={closeDrawer}
                                    // style={{ background: "#FAFAFA" }}
                                    dense>
                                    {link.links.map((sideLink, index) => {
                                      if (
                                        user.role == "Admin" ||
                                        userPermissions[sideLink.labelEn]
                                      ) {
                                        return (
                                          <UISref
                                            key={
                                              isArb
                                                ? sideLink.labelAr
                                                : sideLink.labelEn
                                            }
                                            to={sideLink.sref}
                                            params={{ type: sideLink.type }}>
                                            <ListItem
                                              component={Grid}
                                              container
                                              direction="column"
                                              wrap="nowrap"
                                              button
                                              className={
                                                sideLink.sref === "users" &&
                                                user.role === "SubAdmin"
                                                  ? ""
                                                  : ""
                                              }
                                              classes={{
                                                root: classNames({
                                                  [classes.activeSideLink]:
                                                    activeState &&
                                                    activeState.name ===
                                                      sideLink.sref,
                                                  [classes.nestedLink]:
                                                    sideNavOpen,
                                                }),
                                              }}
                                              onClick={async () => {
                                                // setLinks(linkData)
                                              }}>
                                              {console.log(
                                                "banner enterd ",
                                                $state.params
                                              )}
                                              <Grid
                                                container
                                                alignItems="center"
                                                className={
                                                  index <
                                                    link.links.length - 1 &&
                                                  classes.nestedLinkBottom
                                                }>
                                                <Grid
                                                  item
                                                  xs
                                                  container
                                                  alignItems="center"
                                                  className="sideNavLink">
                                                  {
                                                    // sideNavOpen &&
                                                    <span className="side-menu-link-text">
                                                      {isArb
                                                        ? sideLink.labelAr
                                                        : sideLink.labelEn}
                                                    </span>
                                                  }
                                                  {sideLink.lock ? (
                                                    <i className="fa fa-lock ml-2"></i>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Grid>
                                              </Grid>
                                            </ListItem>
                                          </UISref>
                                        );
                                      }
                                    })}
                                  </List>
                                </Collapse>
                              </>
                            )}
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <React.Fragment
                            key={isArb ? link.labelAr : link.labelEn}>
                            <ListItem
                              ref={(ref) => (link.elementRef = ref)}
                              button
                              classes={{
                                root: classNames({
                                  [classes.activeLink]:
                                    activeState &&
                                    activeState.name === link.sref,
                                }),
                              }}
                              onClick={async () => {
                                router.stateService.go(link.sref);
                                // setLinks(linkData)
                                closeDrawer();
                              }}
                              // onMouseEnter={() => {
                              //     if (!sideNavOpen)
                              //         toggleCollapse(index, true)
                              // }}
                            >
                              <Grid
                                item
                                xs
                                container
                                alignItems="center"
                                className="sideNavLink">
                                {link.icon}
                                {
                                  // sideNavOpen &&
                                  <span className="pl-2 side-menu-link-text">
                                    {isArb ? link.labelAr : link.labelEn}
                                  </span>
                                }
                                {link.lock ? (
                                  <i className="fa fa-lock ml-2"></i>
                                ) : (
                                  ""
                                )}
                              </Grid>
                              {
                                // sideNavOpen &&
                                link.links && (
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      size="small"
                                      color="inherit"
                                      onClick={() => {
                                        toggleCollapse(index);
                                      }}>
                                      {!link.open ? (
                                        <ChevronDown
                                          className="tex-gray"
                                          width={20}
                                        />
                                      ) : (
                                        <ChevronUp
                                          className="text-gray"
                                          width={20}
                                        />
                                      )}
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                )
                              }
                            </ListItem>
                          </React.Fragment>
                        );
                      }
                    }
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
        return (
          <>
            <Hidden smDown>
              <Grid
                container
                direction="column"
                wrap="nowrap"
                item
                className={classNames(classes.container, {
                  [classes.containerHide]: !sideNavOpen,
                })}>
                {console.log("drawer ", drawerOpen)}
                {content}
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Drawer
                classes={{
                  paper: classNames(classes.container),
                }}
                onClose={closeDrawer}
                onOpen={openDrawer}
                open={drawerOpen}>
                {content}
              </Drawer>
            </Hidden>
          </>
        );
      }}
    </UIRouterConsumer>
  );
};

// export default withTranslation()(SideNav);
export default SideNav;
