import React, { useState, useContext } from "react";
import { ReactStateDeclaration, UISref } from "@uirouter/react";
import { $user } from "../Services/UserFactory";
import { $state } from "../router";
import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  makeStyles,
  Tabs,
  Tab,
  TextField,
  Hidden,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import { loginEcomUrl, loginFoodUrl } from "../constants";
import LanguageContext from "../LanguageContext/LanguageContext";
import { Arabic } from "./Arabic/Arabic";

const useStyles = makeStyles((theme) => ({
  loginCard: {
    padding: "50px 10px",
    width: 300,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      padding: "50px",
      width: 500,
    },
  },
  leftContainer: {
    background: "#0743C9",
    gridGap: "8px",
  },
  mobileTopContainer: {
    background: "#0743C9",
    height: "8%",
    padding: "1em",
  },
  loginGrid: {
    borderRadius: "8px",
    border: "1px solid #f1f1f1",
    padding: "3.7em 1.5em 1.5em 1.5em",
    gridGap: "16px",
    width: "65%",
    "@media min-width(700px)": {
      width: "70%",
    },
  },
  verticalLine: {
    width: "1px",
    border: "1px solid white",
    height: "6%",
  },
}));

export function Login(props) {
  //language context
  const { isArb } = useContext(LanguageContext);
  const [validated, setValidated] = useState(false);
  const [reveal, setReveal] = useState(false);
  const [params, setParams] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ any: [] });
  const classes = useStyles({});
  const [value, setValue] = useState(0);
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    //Name
    if (!params.username) {
      formIsValid = false;
      errors["userName"] = isArb
        ? Arabic.this_field_is_required
        : "This field required";
      errors["userNameError"] = true;
    }
    //Password
    if (!params.password) {
      formIsValid = false;
      errors["password"] = isArb
        ? Arabic.this_field_is_required
        : "This field required";
      errors["passwordError"] = true;
    }
    setErrors(errors);
    return formIsValid;
  };
  const login = async () => {
    setLoading(true);
    const fromEmail = localStorage.getItem("fromEmail");
    if (handleValidation()) {
      try {
        await $user.login(params);
        if (fromEmail) {
          $state.go("extendPlan");
          localStorage.removeItem("fromEmail");
        } else {
          $state.go("dashboard");
        }
      } catch (e) {}
    }
    setLoading(false);
  };

  const setParam = (name, value) => {
    setParams((prev) => {
      return {
        ...prev,
        [name]: name === "username" ? value.toLowerCase() : value,
      };
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
          <Grid item container xs>
            <Typography>{children}</Typography>
          </Grid>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Grid container style={{ height: "100vh" }}>
      {/* <Grid className="p-3">
            <Card style={{borderRadius: '30px'}} className={[classes.loginCard, "p-2-all"]} component={"form"}
                  onSubmit={e => {
                      e.preventDefault();
                      login();
                  }}>
                <Grid item xs={12} container justify={"center"}>
                    <Grid item xs={4} sm={3} md={3} className="loginLogo mb-3">
                        <img alt={ isArb ? Arabic.logo : 'Logo' } className="w-75" src={process.env.PUBLIC_URL + "/assets/images/res-logo.png"}/>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                    <Typography variant={"h6"}>{ isArb ? Arabic.seller_login:'Seller Login' }</Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                    <Typography variant={"body2"} className="text-left font-weight-bold">{ isArb ? Arabic.select_your_store_type:'Select your Store Type' }</Typography>
                </Grid>
                <Grid item xs={12} container justify={"center"}>
                    <Grid item xs={6} md={6} sm={6}>
                        <Button onClick={() => {
                            window.location.href = loginFoodUrl
                        }} variant="contained" style={{backgroundColor: '#010123', color: 'white'}}
                                fullWidth>{ isArb ? Arabic.food:'Food' }</Button>
                    </Grid>
                    <Grid item xs={6} md={6} sm={6}>
                        <Button onClick={() => {
                            window.location.href = loginEcomUrl
                        }} variant="outlined" fullWidth>{ isArb ? Arabic.e_commerse:'E-Commerce' }</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="mb-2">
                    <FormControl className="" variant="outlined" size="small" fullWidth={true}>
                        <OutlinedInput
                            placeholder={ isArb ? Arabic.email:'Email *' }
                            id="input-with-icon-textfield"
                            size={'small'}
                            fullWidth={true}
                            autoComplete="off"
                            error={errors.userNameError} helperText={errors.userName}
                            value={params.username} onChange={e => setParam("username", e.target.value)}
                            name="username"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className="" variant="outlined" size="small" fullWidth={true}>
                        <OutlinedInput
                            id="standard-adornment-password"
                            type={reveal ? 'text' : 'password'}
                            error={errors.passwordError}
                            value={params.password}
                            onChange={e => setParam("password", e.target.value)}
                            name="password"
                            placeholder={ isArb ? Arabic.password:'Password *' }
                            fullWidth={true}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton style={{padding: 5}}
                                                aria-label="toggle password visibility"
                                                onClick={() => {
                                                    setReveal(!reveal)
                                                }}
                                    >
                                        {reveal ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {
                        errors.password &&
                        <small className="text-danger m-0">{errors.password}</small>
                    }
                </Grid>
                <Grid xs={12} item align="right" className="pt-0">
                    <UISref to={'forgotPassword'}><span className="forgotPass">{ isArb ? Arabic.forgot_password:'Forgot Password?' }</span></UISref>
                </Grid>
                <Button className="mt-3" style={{height: 46, width: 163, margin: 'auto'}} variant="contained"
                        disabled={loading} type="submit" color="primary">   {loading ? isArb ? Arabic.signing_in:'Sign in..' : isArb ? Arabic.sign_in:'Sign in'}</Button>
            </Card>
        </Grid> */}
      <Hidden xsDown>
        <Grid
          item
          sm={6}
          container
          className={classes.leftContainer}
          alignItems="center"
          justify="center">
          <Grid item>
            <Typography
              variant="h4"
              style={{
                color: "white",
                fontFamily: "nunito",
                letterSpacing: "0.5px",
                fontWeight: 900,
              }}>
              Viral
            </Typography>
          </Grid>
          <Grid item container className={classes.verticalLine}></Grid>
          <Grid item wrap="wrap" style={{ width: "40%", color: "white" }}>
            <Typography variant="caption">
              Manage your orders, products, customers, promo code's, etc...{" "}
            </Typography>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid
          item
          xs={12}
          container
          className={classes.mobileTopContainer}
          alignContent="center">
          <Typography variant="h5" style={{ color: "white" }}>
            Viral
          </Typography>
        </Grid>
      </Hidden>
      <Grid
        item
        xs={12}
        sm={6}
        container
        alignItems="center"
        justify="center"
        style={{ height: "100%" }}>
        <Grid item container direction="column" className={classes.loginGrid}>
          <Grid item container direction="column" style={{ gridGap: "8px" }}>
            <Typography variant="h5" style={{ fontWeight: "300" }}>
              Login to your store
            </Typography>
            <Typography variant="caption" style={{ color: "grey" }}>
              Select your Store Type and get started
            </Typography>
          </Grid>
          <Grid item container style={{ gridGap: "16px" }} direction="column">
            <Grid item xs={12}>
              {/* <FormControl className="" variant="outlined" size="small" fullWidth={true}> */}
              <TextField
                placeholder={isArb ? Arabic.email : "Email *"}
                id="input-with-icon-textfield"
                size={"small"}
                fullWidth={true}
                color="primary"
                variant="outlined"
                autoComplete="off"
                error={errors.userNameError}
                helperText={errors.userName}
                value={params.username}
                onChange={(e) => setParam("username", e.target.value)}
                name="username"
              />
              {/* </FormControl> */}
            </Grid>
            <Grid item container>
              <Grid item xs={12}>
                <FormControl
                  className=""
                  variant="outlined"
                  size="small"
                  fullWidth={true}>
                  <OutlinedInput
                    id="standard-adornment-password"
                    type={reveal ? "text" : "password"}
                    error={errors.passwordError}
                    value={params.password}
                    onChange={(e) => setParam("password", e.target.value)}
                    name="password"
                    placeholder={isArb ? Arabic.password : "Password *"}
                    fullWidth={true}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          style={{ padding: 5 }}
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setReveal(!reveal);
                          }}>
                          {reveal ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {errors.password && (
                  <small className="text-danger m-0">{errors.password}</small>
                )}
              </Grid>
              <Grid xs={12} item align="right">
                <UISref to={"forgotPassword"}>
                  <Typography
                    variant="caption"
                    style={{ color: "grey", cursor: "pointer" }}>
                    {isArb ? Arabic.forgot_password : "Forgot Password?"}
                  </Typography>
                </UISref>
              </Grid>
            </Grid>
            <Grid item container justify="center">
              <Button
                variant="contained"
                disabled={loading}
                type="submit"
                color="primary"
                onClick={login}>
                {loading
                  ? isArb
                    ? Arabic.signing_in
                    : "Sign in.."
                  : isArb
                  ? Arabic.sign_in
                  : "Sign in"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export const states: ReactStateDeclaration[] = [
  {
    url: "/",
    name: "login",
    data: {
      title: "Login",
      loggedOut: true,
    },
    component: Login,
  },
];
