import { UIRouterReact } from "@uirouter/react";
import { hashLocationPlugin, servicesPlugin } from "@uirouter/core";
import { Login } from "./components/Login";

export const router = new UIRouterReact();

const states = [
  {
    url: "/",
    name: "login.**",
    lazyLoad: () => import("./components/Login"),
  },
  {
    url: "/seller-login/:id/:type",
    name: "sellerLogin.**",
    lazyLoad: () => import("./components/SellerLogin"),
  },
  {
    url: "/change-password",
    name: "changePassword.**",
    lazyLoad: () => import("./components/ChangePassword"),
  },
  {
    url: "/reset-password",
    name: "resetPassword.**",
    lazyLoad: () => import("./components/ResetPassword"),
  },
  {
    url: "/forgot-password",
    name: "forgotPassword.**",
    lazyLoad: () => import("./components/ForgotPassword"),
  },
  {
    url: "/dashboard",
    name: "dashboard.**",
    lazyLoad: () => import("./components/Dashboard"),
  },
  {
    url: "/top-selling-products",
    name: "topSellingProducts.**",
    lazyLoad: () => import("./components/TopSellingProducts"),
  },
  {
    url: "/profile",
    name: "profile.**",
    lazyLoad: () => import("./components/Profile"),
  },
  {
    url: "/category-management",
    name: "categoryManagement.**",
    lazyLoad: () => import("./components/CategoryManagement"),
  },
  {
    url: "/products",
    name: "products.**",
    lazyLoad: () => import("./components/Products"),
  },
  {
    url: "/add-product",
    name: "addProduct.**",
    lazyLoad: () => import("./components/AddProduct"),
  },
  {
    url: "/edit-product/:id",
    name: "editProduct.**",
    lazyLoad: () => import("./components/EditProduct"),
  },
  {
    url: "/product-images/:id",
    name: "productImages.**",
    lazyLoad: () => import("./components/ProductImages"),
  },
  {
    url: "/store-settings",
    name: "storeSettings.**",
    lazyLoad: () => import("./components/StoreSettings"),
  },
  {
    url: "/email-permission",
    name: "emailPermission.**",
    lazyLoad: () => import("./components/EmailPermissions"),
  },
  {
    url: "/add-on's",
    name: "addons.**",
    lazyLoad: () => import("./components/Addons"),
  },
  {
    url: "/add-addons",
    name: "addAddons.**",
    lazyLoad: () => import("./components/AddAddons"),
  },
  {
    url: "/edit-addons/:id",
    name: "editAddons.**",
    lazyLoad: () => import("./components/EditAddons"),
  },
  {
    url: "/addons-images/:id",
    name: "addonsImages.**",
    lazyLoad: () => import("./components/AddonsImages"),
  },
  {
    url: "/removal-products",
    name: "removalProducts.**",
    lazyLoad: () => import("./components/RemovalProducts"),
  },
  {
    url: "/customers",
    name: "customers.**",
    lazyLoad: () => import("./components/Customers/Customers"),
  },
  {
    url: "/manage-banners/:type",
    name: "manageBanner.**",
    lazyLoad: () => import("./components/HomeBanner"),
  },
  {
    url: "/add-banner/:type",
    name: "addBanner.**",
    lazyLoad: () => import("./components/AddBanner"),
  },
  {
    url: "/edit-banner?:id&:type",
    name: "EditBanner.**",
    lazyLoad: () => import("./components/EditBanner"),
  },
  {
    url: "/testimonials",
    name: "testimonials.**",
    lazyLoad: () =>
      import("./components/LandingPages/Testimonials/Testimonials"),
  },
  {
    url: "/promotions",
    name: "promotions.**",
    lazyLoad: () => import("./components/LandingPages/Promotions/Promotions"),
  },
  {
    url: "/gallery-list",
    name: "galleryList.**",
    lazyLoad: () => import("./components/LandingPages/Gallery/GalleryList"),
  },
  {
    url: "/blogs",
    name: "blogs.**",
    lazyLoad: () => import("./components/LandingPages/Blogs/Blogs"),
  },
  {
    url: "/add-blog",
    name: "addBlog.**",
    lazyLoad: () => import("./components/LandingPages/Blogs/AddBlog"),
  },
  {
    url: "/edit-blog/:id",
    name: "editBlog.**",
    lazyLoad: () => import("./components/LandingPages/Blogs/EditBlog"),
  },
  {
    url: "/most-selling",
    name: "mostSelling.**",
    lazyLoad: () => import("./components/LandingPages/MostSelling/MostSelling"),
  },
  {
    url: "/orders",
    name: "orders.**",
    lazyLoad: () => import("./components/Orders"),
  },
  {
    url: "/incomplete-orders",
    name: "incompleteOrders.**",
    lazyLoad: () => import("./components/IncompleteOrders"),
  },
  {
    url: "/order-details/:id/:fromReport",
    name: "orderDetails.**",
    lazyLoad: () => import("./components/OrderDetails"),
  },
  {
    url: "/order-details-report/:id",
    name: "ReportOrderDetails.**",
    lazyLoad: () => import("./components/Reports/ReportOrderDetails"),
  },
  {
    url: "/incompleteOrder-details/:id",
    name: "incompleteOrderDetails.**",
    lazyLoad: () => import("./components/IncompleteOrderDetail"),
  },
  {
    url: "/print-invoice/:id",
    name: "printInvoice.**",
    lazyLoad: () => import("./components/PrintInvoice"),
  },
  {
    url: "/customer-details/:id",
    name: "customerDetails.**",
    lazyLoad: () => import("./components/Customers/CustomerDetails"),
  },
  {
    url: "/store-branches",
    name: "storeBranches.**",
    lazyLoad: () => import("./components/Branches/StoreBranches"),
  },
  {
    url: "/add-store-branch",
    name: "addStoreBranch.**",
    lazyLoad: () => import("./components/Branches/AddStoreBranch"),
  },
  {
    url: "/edit-store-branch/:id",
    name: "editStoreBranch.**",
    lazyLoad: () => import("./components/Branches/EditStoreBranch"),
  },
  {
    url: "/manage-cms",
    name: "cms.**",
    lazyLoad: () => import("./components/cms/Cms"),
  },
  {
    url: "/cms/edit/:id",
    name: "editCms.**",
    lazyLoad: () => import("./components/cms/EditCms"),
  },
  {
    url: "/cms/add",
    name: "addCms.**",
    lazyLoad: () => import("./components/cms/AddCms"),
  },
  {
    url: "/manage-faq",
    name: "faq.**",
    lazyLoad: () => import("./components/faq/Faq"),
  },
  {
    url: "/faq/add",
    name: "addFAQ.**",
    lazyLoad: () => import("./components/faq/Add"),
  },
  {
    url: "/faq/edit/:id",
    name: "editFAQ.**",
    lazyLoad: () => import("./components/faq/Edit"),
  },
  {
    url: "/manage-coupons",
    name: "coupons.**",
    lazyLoad: () => import("./components/coupons/Coupons"),
  },
  {
    url: "/coupons/history/:id/:code",
    name: "couponUsageHistory.**",
    lazyLoad: () => import("./components/coupons/CouponUsageHistory"),
  },
  {
    url: "/coupon/add",
    name: "addCoupon.**",
    lazyLoad: () => import("./components/coupons/AddCoupon"),
  },
  {
    url: "/coupon/edit/:id",
    name: "editCoupon.**",
    lazyLoad: () => import("./components/coupons/EditCoupon"),
  },
  {
    url: "/newsletter-emails",
    name: "emailSubscription.**",
    lazyLoad: () => import("./components/Emails/EmailSubscription"),
  },
  {
    url: "/delivery-charges",
    name: "deliveryCharges.**",
    lazyLoad: () => import("./components/DeliveryCharges/DeliveryCharges"),
  },
  {
    url: "/create-delivery-charges",
    name: "createDeliveryCharges.**",
    lazyLoad: () =>
      import("./components/DeliveryCharges/CreateDeliveryCharges"),
  },
  {
    url: "/edit-delivery-charges/:id",
    name: "editDeliveryCharges.**",
    lazyLoad: () => import("./components/DeliveryCharges/EditDeliveryCharges"),
  },
  {
    url: "/users",
    name: "users.**",
    lazyLoad: () => import("./components/Users/Users"),
  },
  {
    url: "/create-user",
    name: "createUser.**",
    lazyLoad: () => import("./components/Users/CreateUser"),
  },
  {
    url: "/payments",
    name: "payments.**",
    lazyLoad: () => import("./components/Payments/Payments"),
  },
  {
    url: "/payments-report",
    name: "paymentsReport.**",
    lazyLoad: () => import("./components/Reports/PaymentsReport"),
  },
  {
    url: "/sales-report",
    name: "saleReports.**",
    lazyLoad: () => import("./components/Reports/SalesReport"),
  },
  {
    url: "/salesorder-report?from&&to",
    name: "saleOrderReports.**",
    lazyLoad: () => import("./components/Reports/SalesOrderReport"),
  },
  {
    url: "/profit-report",
    name: "profitReport.**",
    lazyLoad: () => import("./components/Reports/ProfitReport"),
  },
  {
    url: "/profit-report/:id",
    name: "profitReportDetail.**",
    lazyLoad: () => import("./components/Reports/ProfitReportDetail"),
  },
  {
    url: "/contact-us",
    name: "contactUs.**",
    lazyLoad: () => import("./components/ContactUs/Contact"),
  },
  {
    url: "/edit-user/:id",
    name: "editUser.**",
    lazyLoad: () => import("./components/Users/EditUser"),
  },
  {
    url: "/user-permission/:id",
    name: "userPermissions.**",
    lazyLoad: () => import("./components/Users/UserPermissions"),
  },
  {
    url: "/extend-plan?fromEmail=true",
    name: "extendPlan.**",
    lazyLoad: () => import("./components/ExtendPlan"),
  },
  {
    url: "/google-analytic-script",
    name: "googleAnalytic.**",
    lazyLoad: () => import("./components/MyStore/GoogleAnalytic"),
  },
  {
    url: "/chat-script",
    name: "chatScript.**",
    lazyLoad: () => import("./components/MyStore/ChatScript"),
  },
  {
    url: "/no-permission",
    name: "noPermission.**",
    lazyLoad: () => import("./components/NoPermission"),
  },
  {
    url: "/option-types",
    name: "optionTypes.**",
    lazyLoad: () => import("./components/ProductMaster/OptionType/OptionTypes"),
  },
  {
    url: "/product-options",
    name: "productOptions.**",
    lazyLoad: () => import("./components/ProductMaster/Options/ProductOptions"),
  },
  {
    url: "/my-referrals",
    name: "affiliateDetails.**",
    lazyLoad: () => import("./components/Affiliate/AffiliateDetails"),
  },
  {
    url: "/scheduling",
    name: "scheduling.**",
    lazyLoad: () => import("./components/Scheduling"),
  },
  {
    url: "/payment-fail?merchantTxnId&errorMessage",
    name: "paymentFail.**",
    lazyLoad: () => import("./components/Payments/PaymentFail"),
  },
  {
    url: "/payment-success?merchantTxnId&errorMessage",
    name: "paymentSuccess.**",
    lazyLoad: () => import("./components/Payments/PaymentSuccess"),
  },
  {
    url: "/upgrade-history",
    name: "upgradeHistory.**",
    lazyLoad: () => import("./components/UpgradeHistory"),
  },
  {
    url: "/add-customers",
    name: "addCustomer.**",
    lazyLoad: () => import("./components/Customers/AddCustomer"),
  },
  {
    url: "/order-report",
    name: "orderReport.**",
    lazyLoad: () => import("./components/Reports/OrderReport"),
  },
  {
    url: "/third-party-services",
    name: "thirdPartyServices.**",
    lazyLoad: () => import("./components/ThirdPartyServices"),
  },
  {
    url: "/custom-management",
    name: "customManagement.**",
    lazyLoad: () => import("./components/Custom/Custom"),
  },
];

states.forEach((state) => router.stateRegistry.register(state));

router.urlRouter.otherwise("/");
router.plugin(hashLocationPlugin);
router.plugin(servicesPlugin);

export const $state = router.stateService;
export const $transition = router.transitionService;
