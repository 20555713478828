import {$crud} from "./CrudFactory";
import {SET_PERMISSIONS, SET_SETTING, SET_USER, SET_ORDER_REPORT, SET_PROFIT_REPORT, SET_PAYMENTS_REPORT, SET_SALES_REPORT, SET_ORDERS, SET_PRODUCTS, store} from "../store";
import _ from "lodash";
import moment from "moment";
import {useSelector} from "react-redux";


export class UserFactory {

    constructor() {
        const user = this.get();
        store.dispatch({
            type: SET_USER,
            user: user?.user
        })
    }


    async login(params) {
        // @ts-ignore
        const {userToken, data} = await $crud.post("user/login", params);
        this.setToken(userToken);
        this.set(data)
    }

    async loginFromAdmin(params) {
        // @ts-ignore
        const {userToken, data} = await $crud.post("user/login_from_admin", params);
        this.setToken(userToken);
        this.set(data)
    }

    async logout() {
        this.removeToken();
        this.remove();
        this.removeSetting()
    }

    async current() {
        return this.get()?.user;
    }


    get tz() {
        const {user} = store.getState();
        return _.get(user, "tenant.tz", moment.tz.guess());
    }

    setToken(token) {
        localStorage.setItem("login_token", token);
    }

    remove() {
        localStorage.removeItem("user");
        store.dispatch({
            type: SET_USER,
            user: null
        })
    }

    profitReport(data) {
        store.dispatch({
            type: SET_PROFIT_REPORT,
            profitReport: data
        })
    }

    paymentReport(data) {
        store.dispatch({
            type: SET_PAYMENTS_REPORT,
            paymentReports: data
        })
    }

    orderReport(data) {
        store.dispatch({
            type: SET_ORDER_REPORT,
            orderReport: data
        })
    }

    salesReport(data) {
        store.dispatch({
            type: SET_SALES_REPORT,
            salesReport: data
        })
    }

    setOrders(data) {
        store.dispatch({
            type: SET_ORDERS,
            orders: data
        })
    }

    setProducts(data) {
        store.dispatch({
            type: SET_PRODUCTS,
            product: data
        })
    }

    removeToken() {
        localStorage.removeItem("login_token");
    }

    removeSetting() {
        localStorage.removeItem("setting");
    }

    getToken() {
        return localStorage.getItem("login_token");
    }

    async set(data) {
        localStorage.setItem("user", JSON.stringify(data));
        store.dispatch({
            type: SET_USER,
            user: data?.user
        })
    }

    get() {
        try {
            return JSON.parse(localStorage.getItem("user"));
        } catch (e) {
            console.warn(e);
            return null;
        }
    }

    fetchSettings = async () => {
        const {data: {setting, thumbnail_image_url, favicon_url}} = await $crud.get('setting/')
        let settings = {...setting, thumbnail_image_url: thumbnail_image_url, favicon_url: favicon_url}
        await localStorage.setItem("setting", JSON.stringify(settings));
        store.dispatch({
            type: SET_SETTING,
            setting: setting
        })
    }

    getSetting() {
        try {
            return JSON.parse(localStorage.getItem("setting"));
        } catch (e) {
            console.warn(e);
            return null;
        }
    }

    async getPermissions() {
        // @ts-ignore
        const user = await $user.current();
        const {permissions} = await $crud.get('user/user_permission/' + user._id);
        store.dispatch({
            type: SET_PERMISSIONS,
            permissions: permissions
        })
        return permissions;
    }

}

export const $user = new UserFactory();

export function useCurrentUser() {
    return useSelector(state => state.user);
}

export function useCurrentUserData() {
    return $user.get();
}

export function getUserPermissions() {
    return $user.getPermissions()
}
