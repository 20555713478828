import {SET_LOADER, store} from "./store";

export const show_loader = () => {
    store.dispatch({
        type: SET_LOADER,
        loader: true
    })
}

export const hide_loader = () => {
    store.dispatch({
        type: SET_LOADER,
        loader: false
    })
}
