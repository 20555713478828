import React, { useState, useRef } from "react";
import classNames from "classnames";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { TopNav } from "./TopNav";
import { UIRouter, UIView } from "@uirouter/react";
import { hashLocationPlugin, servicesPlugin } from "@uirouter/core";
import { $state, $transition, router } from "./router";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import SideNav from "./SideNav";
import { useSelector } from "react-redux";
import CrudProvider from "@crud/react/CrudProvider";
import { $crud } from "./Services/CrudFactory";
import { $user } from "./Services/UserFactory";
import { ConfirmDialog, NotifySnackbar } from "react-material-crud";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useIdleTimer } from "react-idle-timer";
import { LanguageProvider } from "./LanguageContext/LanguageContext";
import { store } from "./store";
import { SET_ACTIVE_STATE } from "./constants";

const plugins = [hashLocationPlugin, servicesPlugin];
const primaryPalette = {
  50: "#e6f7fc",
  100: "#c1ecf8",
  200: "#97dff3",
  300: "#6dd2ee",
  400: "#4ec8ea",
  500: "#2fbee6",
  600: "#2ab8e3",
  700: "#23afdf",
  800: "#1da7db",
  900: "#1299d5",
  1000: "#0743C9",
  2000: "#6F72EA",
  A100: "#ffffff",
  A200: "#d0efff",
  A400: "#9ddfff",
  A700: "#84d6ff",
  A800: "#FF0021",
  contrastDefaultColor: "dark",
};

const theme = createMuiTheme({
  // shape: {
  //   borderRadius: "99999px",
  // },
  palette: {
    primary: {
      light: "primaryPalette[1000]",
      main: primaryPalette[1000],
      dark: primaryPalette[1000],
      contrastText: "#fff",
    },
    secondary: {
      main: primaryPalette[2000],
      contrastText: "#000",
    },
  },
  typography: {
    fontFamily: "Poppins, Helvetica, Arial, sans-serif",
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
      root: {
        fontSize: "0.85rem",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: "1px solid #f1f1f1 !important",
        borderRadius: "99999px",
      },
    },
    MuiTableContainer: {
      root: {
        border: "1px solid #f1f1f1",
        borderRadius: "8px",
        background: "white",
      },
    },

    MuiTableCell: {
      root: {
        borderBottom: "none",
        fontSize: "13px",
      },
      body: {
        color: "#222222",
        letterSpacing: "0.5",
      },
    },
    MuiTableRow: {
      root: {
        borderBottom: "1px solid #f1f1f1",
        "&:nth-of-type(even)": {
          backgroundColor: "#fff",
        },
        "&:last-child": {
          borderBottom: 0,
        },
      },
    },
    MuiTableHead: {
      root: {
        background: "#f9f9f9",
        borderRadius: "8px !important",
        borderBottom: "1px solid #f1f1f1",
      },
    },
    MuiIconButton: {
      root: {
        padding: 3,
      },
    },
    // MuiOutlinedInput: {
    //     inputMarginDense: {
    //         padding: "6px !important"
    //     },
    //     shrink: {
    //         transform: "translate(14px,-6px) scale(0.75) !important"
    //     },
    //     root:{
    //         fontSize:"12px"
    //     }
    // },
    // MuiTextField: {
    //     root: {
    //         "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
    //             transform: "translate(14px,8px) scale(1)"
    //         },
    //         "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    //             transform: "translate(14px,-6px) scale(0.75) !important"
    //         }
    //     }
    // },
    // MuiInputLabel: {
    //     outlined: {
    //         // transform: "translate(14px,8px) scale(1)",
    //         color: "black",
    //         fontSize: "12px",
    //         padding: "0px"
    //     },
    //     root: {
    //         fontSize: "12px",
    //         color: "black",
    //         paddingLeft: "2px"
    //     }
    // },
    // MuiSelect: {
    //     select: {
    //         fontSize: "12px"
    //     }
    // },

    MuiListItem: {
      root: {
        fontSize: "12px !important",
        color: "black",
      },
      container: {
        color: "black",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "99999px",
        boxShadow: "unset !important",
      },
      containedPrimary: {
        textTransform: "none",
        whiteSpace: "nowrap",
      },
      outlinedPrimary: {
        textTransform: "none",
        whiteSpace: "nowrap",
        color: "black",
      },
      outlinedError: {
        textTransform: "none",
        whiteSpace: "nowrap",
        color: "black",
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: "1.3rem",
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: "none",
      },
    },
    // MuiDialog: {
    //     paper: {
    //         width: "60%",
    //         // height:"50%",
    //         gridGap: "8px",
    //         padding: "0.7em 1em",
    //         flexDirection: "row !important",
    //         alignItems: "center",
    //         borderRadius: "3px"
    //     }
    // },
    // MuiDialogTitle: {
    //     root: {
    //         padding: "unset"
    //     }
    // },
    // MuiDialogContent: {
    //     root: {
    //         padding: "unset !important",
    //         gridGap: "2em",
    //         border: "2px solid #f5f5f5",
    //         borderRadius: "3px"
    //     }
    // },
    // MuiDialogActions: {
    //     root: {
    //         padding: "unset"
    //     }
    // },
    MuiSelect: {
      icon: {
        top: "unset",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "unset",
        // background: "#fff",
        borderRadius: "99999px !important",
        outline: 0,
      },
    },
    MuiChip: {
      root: {
        borderRadius: "5px",
        border: "0.5px solid #0044C9",
      },
      colorPrimary: {
        backgroundColor: "#F7FAFF !important",
        color: "#0044C9",
      },
      deleteIcon: {
        height: "1.2rem",
        color: "#0044C9 !important",
      },
      label: {
        paddingLeft: "0.8rem",
        paddingRight: "0.5rem",
      },
    },
    root: {
      selected: {
        background: "green",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        boxShadow: "none",
        color: "#0743C9",
        backgroundColor: "unset",
      },
    },
    MuiTab: {
      fullWidth: {
        border: "1px solid #e3e3e3",
        textTransform: "none",
      },
      textColorInherit: {
        opacity: 1,
      },
    },
  },
});
const useStyles = makeStyles({
  appContainer: {
    backgroundColor: "#fff",
    overflowY: "scroll",
  },
  appWrapper: {
    backgroundImage: "#fff",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: "80%",
  },
  "@global": {
    body: {
      // fontFamily: theme.typography.fontFamily
    },
  },
});

$transition.onStart({}, async (trans) => {
  const to = trans.to();
  const loggedIn = to.data?.loggedIn;
  const loggedOut = to.data?.loggedOut;

  if (loggedIn || loggedOut) {
    const user = await $user.current();
    if (to.name === "sellerLogin") {
      localStorage.removeItem("setting");
      localStorage.removeItem("login_token");
      localStorage.removeItem("user");
    }
    if (user && loggedOut && user.business_type === "food_ordering_services") {
      return $state.target(
        "dashboard",
        {},
        {
          location: "replace",
        }
      );
    } else if (!user && loggedIn) {
      return $state.target(
        "login",
        {},
        {
          location: "replace",
        }
      );
    }
  }
});
$transition.onBefore({}, async (trans) => {
  $crud.toggleLoading(true);
});

$transition.onSuccess({}, (trans) => {
  console.log();
  store.dispatch({
    type: SET_ACTIVE_STATE,
    activeState: trans.to(),
  });
  $crud.toggleLoading(false);
});

$transition.onError({}, () => {
  $crud.toggleLoading(false);
});
$transition.onSuccess({}, async (trans) => {
  const to = trans.to();
  // await $user.getPermissions()
});

function App() {
  const user = useSelector((state) => state.user);
  const classes = useStyles({});
  let loader = useSelector((state) => state.loader);
  const [isArb, setIsArb] = useState(localStorage.getItem("switch") === "2");
  const lgVal = { isArb, setIsArb };
  const myRef = useRef();
  const topNavRef = useRef();

  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
  };

  const handleOnActive = (event) => {
    // console.log('user is active', event)
    // console.log('time remaining', getRemainingTime())
    if (getRemainingTime() <= 0) {
      $user.logout();
      $state.go("login");
    }
  };

  const handleOnAction = (e) => {
    // console.log('user did something', e)
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 480,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  // const retrieveLanguage = async () => {
  //     try {
  //         const value = await localStorage.getItem("switch");
  //         if (value !== null) {
  //             value == 1 ? setIsArb(false) : setIsArb(true);
  //         } else {
  //             localStorage.setItem("switch", 1);
  //             setIsArb(false);
  //         }
  //     } catch (error) {
  //         console.log("error language retrieval");
  //     }
  // };
  // useEffect(() => {
  //     retrieveLanguage();
  // }, []);

  return (
    <LanguageProvider value={lgVal}>
      <CrudProvider crud={$crud}>
        <UIRouter router={router}>
          <ThemeProvider theme={theme}>
            <Grid className={classNames(classes.appWrapper)} container>
              {loader && (
                <LinearProgress className="loader" color="secondary" />
              )}

              {/* <Grid item container>
                <Grid item container xs={2}>
                  <SideNav />
                </Grid>
                <Grid item container xs={10}>
                  <Grid item xs={12}>
                    {user && <TopNav ref={topNavRef} />}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      maxWidth: "fit-content",
                      height: "100vh",
                      paddingBottom: "80px",
                    }}
                    className={classNames(classes.appContainer)}>
                    <UIView />
                  </Grid>
                </Grid>
              </Grid> */}
              {/* 
              {user && (
                // <Grid item container>
                <TopNav ref={topNavRef} />
                // </Grid>
              )} */}
              {/*  style={{ height: "100%" }} */}
              <Grid item container>
                {user && (
                  //,height:"90%"
                  <Grid
                    item
                    xs
                    container
                    style={{ maxWidth: "fit-content", height: "100vh" }}>
                    <SideNav />
                  </Grid>
                )}
                {/*  style={{ maxHeight: "90%", height: "fit-content" }} */}
                <Grid
                  style={{
                    maxHeight: user ? "100vh" : "100vh",
                    height: "fit-content",
                  }}
                  item
                  xs
                  container
                  className={classNames(classes.appContainer)}>
                  {user && <TopNav ref={topNavRef} />}

                  <UIView />
                </Grid>
              </Grid>
              {/*{loader && <Loader/>}*/}
              <NotifySnackbar autoHideDuration={5000} />
              <ConfirmDialog />
            </Grid>
          </ThemeProvider>
        </UIRouter>
      </CrudProvider>
    </LanguageProvider>
  );
}

export default App;
