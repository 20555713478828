import {
  AppBar,
  Button,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Avatar,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_DRAWER, TOGGLE_SIDE_NAV } from "./constants";
import { UISref } from "@uirouter/react";
import {
  $user,
  useCurrentUser,
  getUserPermissions,
} from "./Services/UserFactory";
import { $state } from "./router";
import {
  Edit,
  Globe,
  List,
  LogOut,
  Menu as MenuIcon,
  User,
} from "react-feather";
import Tooltip from "@material-ui/core/Tooltip";
import InfoDialog from "./components/dialog/InfoDialog";
import Box from "@material-ui/core/Box";
import LanguageContext from "./LanguageContext/LanguageContext";
import { Arabic } from "./components/Arabic/Arabic";
import { $crud } from "./Services/CrudFactory";
import { ChangePassword } from "./components/ChangePassword";
import { MenuRounded, TrafficTwoTone } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#000",
    background: "#fff",
    borderBottom: "1px solid #f1f1f1",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.04)",
    width: "100%",
  },
  toolbar: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: 15,
    },
    height: 80,
    paddingRight: 15,
    width: "100%",
  },
  menuIcon: {
    borderRight: "1px solid #f1f1f1",
    height: "80px",
    width: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export function TopNav() {
  const user = useSelector((state) => state.user);
  const classes = useStyles({});
  const users = useCurrentUser();
  const sideNavOpen = useSelector((state) => state.sideNavOpen);
  const drawerOpen = useSelector((state) => state.drawerOpen);
  const title = useSelector((state) => state.title);
  const [profile, setProfile] = useState(false);
  const [plan, setPlan] = useState(false);
  const dispatch = useDispatch();
  const [imageSize, setImageSize] = useState(
    process.env.PUBLIC_URL + "/info-images/image-resolution.png"
  );
  const [imageSizeView, setImageSizeView] = useState(false);
  const get_setting = useSelector((state) => state.setting);
  const [status, setStatus] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [setting, setSetting] = useState(get_setting);

  let [storeStatusView, setStoreStatusView] = useState(false);
  let [storeStatusEdit, setStoreStatusEdit] = useState(false);

  const openDrawer = useCallback(
    () =>
      dispatch({
        type: TOGGLE_DRAWER,
        open: true,
      }),
    []
  );

  const toggleDrawer = useCallback(
    (open) =>
      dispatch({
        type: TOGGLE_DRAWER,
        open: open,
      }),
    []
  );

  const toggleSideNav = useCallback(
    (open) =>
      dispatch({
        type: TOGGLE_SIDE_NAV,
        sideNavOpen: open,
      }),
    []
  );

  //========Languange selector========//
  const { isArb, setIsArb } = React.useContext(LanguageContext);

  const handleChangeLanguage = () => {
    //change by nabeel
    if (isArb) {
      setLanguage("AR");
      setIsArb(false);
      localStorage.setItem("switch", 1);
    } else if (!isArb) {
      setLanguage("EN");
      setIsArb(true);
      localStorage.setItem("switch", 2);
    }
  };
  //==================================//

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [language, setLanguage] = React.useState(isArb ? "EN" : "AR");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePassword = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const CloseImageDialog = () => {
    setImageSizeView(false);
  };
  useEffect(() => {
    getProfilePermissions();
    getPlanDetailPermissions();
  });

  useEffect(() => {
    if (!setting) {
      const sett = $user.getSetting() || {
        status: false,
        store_name: { en: "Store Name" },
        thumbnail_image_url:
          process.env.PUBLIC_URL + "/assets/images/default-logo.png",
      };
      setStatus(sett?.status);
      setSetting({
        store_name: {
          en: sett.store_name?.en,
        },
        thumbnail_image_url:
          process.env.PUBLIC_URL + "/assets/images/default-logo.png",
      });
    } else {
      setStatus(get_setting.status);
      setSetting({
        store_name: {
          en: get_setting.store_name?.en,
        },
        thumbnail_image_url:
          $user.getSetting()?.thumbnail_image_url !== null
            ? $user.getSetting()?.thumbnail_image_url
            : process.env.PUBLIC_URL + "/assets/images/default-logo.png",
      });
    }
  }, [get_setting]);

  const getProfilePermissions = () => {
    if (user?.role === "SubAdmin") {
      user.permission.permissions.map((item) => {
        if (item.label === "Profile" && item.action.view) {
          setProfile(true);
        }
      });
    }
  };

  const changeStoreStatus = async (sts, e) => {
    await $crud.confirm({
      title: isArb ? Arabic.are_you_sure : "Are you sure?",
      textContent: isArb
        ? Arabic.are_you_sure_to_change_store_status
        : "Are you sure to change store status to:" + sts + "?",
      options: {
        ok: isArb ? Arabic.yes : "Yes",
        cancel: isArb ? Arabic.no : "No",
      },
    });

    await $crud.post("setting/update_store_status", { status: sts });
    const getSetting = setting;
    getSetting.status = sts;
    localStorage.setItem("setting", JSON.stringify(getSetting));
    setStatus(sts);
  };

  const getPlanDetailPermissions = () => {
    if (user?.role === "SubAdmin") {
      user.permission.permissions.map((item) => {
        if (item.label === "Plan Details" && item.action.view) {
          setPlan(true);
        }
      });
    }
  };

  console.log(user);

  return (
    <AppBar
      elevation={0}
      className={classNames(classes.root, "d-print-none")}
      position="static">
      <Toolbar disableGutters className={classNames(classes.toolbar, "")}>
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item xs container wrap="nowrap" alignItems="center">
            <Hidden mdUp>
              <Grid item onClick={openDrawer}>
                <IconButton color="inherit">
                  <MenuRounded fontSize="50px" />
                </IconButton>
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid
                item
                onClick={() => toggleSideNav(!sideNavOpen)}
                className={classes.menuIcon}>
                <IconButton color="inherit">
                  <MenuIcon />
                </IconButton>
              </Grid>
              {/* {setting && (
                <Grid
                  style={{
                    fontSize: 18,
                    textAlign: "left",
                    font: "normal normal normal 35px/58px Arial Rounded MT Bold",
                    letterSpacing: "2.5px",
                    color: "#0A44C9",
                    opacity: "1",
                  }}
                  item
                  xs={12}
                  className='pl-2 text-truncate'>
                  {"Viral"}
                </Grid>
              )} */}
            </Hidden>
          </Grid>
          <Grid
            xs={10}
            spacing={2}
            item
            justify="flex-end"
            alignItems={"center"}
            alignContent="center"
            container
            wrap="nowrap">
            <Grid
              item
              alignItems="center"
              style={{ color: "#4caf50", display: "flex" }}>
              <Typography variant="body2">Visit Store</Typography>
              &nbsp;
              <a target="_blank" href={"http://" + user?.subdomain}>
                <Tooltip title={isArb ? Arabic.visit_store : "Visit Store"}>
                  <IconButton aria-label="show 17 new notifications">
                    <Globe color="#00c020" />
                  </IconButton>
                </Tooltip>
              </a>
            </Grid>
            {setting ? (
              user.role === "Admin" || (storeStatusEdit && storeStatusView) ? (
                <Grid item className="store_status">
                  {status ? (
                    <Grid item className="store-status-select">
                      {isArb ? (
                        Arabic.shop
                      ) : (
                        <TrafficTwoTone
                          style={{
                            color: `${
                              status == "Open"
                                ? "#4caf50"
                                : status == "Busy"
                                ? "#ffeb3b"
                                : "#d50000"
                            }`,
                          }}
                        />
                      )}
                      &ensp;
                      <select
                        onChange={(e) => changeStoreStatus(e.target.value, e)}
                        className="changeStatusSelect">
                        <option selected={status === "Open"} value={"Open"}>
                          {isArb ? Arabic.open : "Open"}
                        </option>
                        <option selected={status === "Close"} value={"Close"}>
                          {isArb ? Arabic.close : "Close"}
                        </option>
                        <option selected={status === "Busy"} value={"Busy"}>
                          {isArb ? Arabic.busy : "Busy"}
                        </option>
                      </select>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              ) : (
                <Grid>
                  {storeStatusView ? (
                    <Grid item className="store_status">
                      {status ? (
                        <Grid item className="pl-3">
                          {isArb ? Arabic.set_status : "Set Status"}:{" "}
                          <label htmlFor="">{status}</label>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  ) : null}
                </Grid>
              )
            ) : (
              ""
            )}
            <Grid item wrap="nowrap">
              {/* <a target="_blank" href={'http://' + user.subdomain}>
                            <Tooltip title={ isArb ? Arabic.visit_store:'Visit Store' }>
                                <IconButton aria-label="show 17 new notifications" color="inherit">
                                    <Globe />
                                </IconButton>
                            </Tooltip>
                        </a> */}
              {/*<IconButton aria-label="show 17 new notifications" color="inherit">*/}
              {/*    <Badge badgeContent={3} color="secondary">*/}
              {/*        <NotificationsIcon/>*/}
              {/*    </Badge>*/}
              {/*</IconButton>*/}
              {/* <span style={{cursor:"pointer", color:"#0743C9"}} onClick={handleChangeLanguage} aria-label="show 17 new notifications" color="inherit">
                            {language}
                        </span> */}
              <IconButton
                style={{ textTransform: "none" }}
                color="inherit"
                onClick={handleClick}>
                {/* <User className={'d-sm-block'} style={{ display: "none" }} /> */}
                {console.log("user", $user?.getSetting()?.imageUrl)}
                <Avatar
                  alt="viral"
                  src={$user?.getSetting()?.imageUrl}
                  style={{
                    background:
                      !$user?.getSetting()?.main_image_url && "#0743C9",
                    fontSize: "1.5em",
                    width: "50px",
                    height: "50px",
                  }}>
                  Z
                </Avatar>
                {/* <span style={{ marginLeft: "4px" }}>
                  {user?.name?.split(" ")[0]}
                </span> */}
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {user.role === "Admin" || profile ? (
                  <UISref to="profile">
                    <MenuItem onClick={handleClose}>
                      <User size={15} />
                      <span className="pl-2">
                        {isArb ? Arabic.profile : "Profile"}
                      </span>
                    </MenuItem>
                  </UISref>
                ) : null}
                {/* <UISref to="changePassword"> */}
                <MenuItem onClick={handleChangePassword}>
                  <Edit size={15} />
                  <span className="pl-2">
                    {isArb ? Arabic.change_password : "Change Password"}
                  </span>
                </MenuItem>
                {/* </UISref> */}
                <Box
                  display={{
                    xs: "block",
                    sm: "block",
                    md: "block",
                    lg: "block",
                  }}>
                  <UISref
                    className={user.role === "SubAdmin" ? "d-none" : ""}
                    to={"extendPlan"}
                    params={{ id: user?._id }}>
                    <MenuItem onClick={handleClose}>
                      <List size={15} />
                      <span className="pl-2">
                        {isArb ? Arabic.plan_details : "Plan Details"}
                      </span>
                    </MenuItem>
                  </UISref>
                </Box>
                <UISref to="login">
                  <MenuItem
                    onClick={async () => {
                      await $user.logout();
                      $state.go("login");
                    }}>
                    <LogOut size={15} />
                    <span className="pl-2">
                      {isArb ? Arabic.logout : "Logout"}
                    </span>
                  </MenuItem>
                </UISref>
              </Menu>
            </Grid>
          </Grid>
          {imageSizeView ? (
            <InfoDialog
              open={imageSizeView}
              image={imageSize}
              handleClose={CloseImageDialog}
            />
          ) : (
            ""
          )}
          {openDialog && (
            <ChangePassword open={openDialog} handleClose={handleDialogClose} />
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
